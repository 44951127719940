import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import logo from "../../../assets/images/LogoBgRemoved.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXTwitter } from "@fortawesome/free-brands-svg-icons";

Footer.propTypes = {};

function Footer(props) {
  /*   setTimeout(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, 100);
 */
  return (
    <footer className="footer">
      <div className="top-footer">
        <div className="tf-container">
          <div className="row">
            <div className="col-lg-2 col-md-4">
              <div className="footer-logo" style={{ paddingTop: "0.5em" }}>
                <Link to="/">
                  <img src={logo} alt="images" className="w-100" />
                </Link>
              </div>
            </div>
            <div className="col-lg-10 col-md-8">
              <div className="wd-social d-flex aln-center">
                <span>Follow Us:</span>
                <ul className="list-social d-flex aln-center">
                  <li>
                    <Link to="#">
                      <i className="icon-facebook"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="icon-linkedin2"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      {/*  <i className="icon-twitter"></i> */}
                      <FontAwesomeIcon icon={faXTwitter} />
                    </Link>
                  </li>
                  <li>
                    {/*   <Link to="#">
                      <i className="icon-pinterest"></i>
                    </Link> */}
                  </li>
                  <li>
                    {/*   <Link to="#">
                      <i className="icon-instagram1"></i>
                    </Link> */}
                  </li>
                  <li>
                    {/* <Link to="#">
                      <i className="icon-youtube"></i>
                    </Link> */}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="inner-footer">
        <div className="tf-container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="footer-cl-1">
                <div className="icon-infor d-flex aln-center">
                  <div className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={35}
                      height={20}
                      viewBox="0 0 52 40"
                      fill="none"
                    >
                      <path
                        d="M47.125 0.5H4.87503C2.18677 0.5 0 2.68678 0 5.37504V34.625C0 37.3133 2.18677 39.5001 4.87503 39.5001H47.125C49.8132 39.5001 52 37.3133 52 34.625V5.37504C52 2.68678 49.8132 0.5 47.125 0.5ZM47.125 3.74999C47.3457 3.74999 47.5558 3.7958 47.7478 3.87579L26 22.7248L4.25209 3.87579C4.4441 3.7959 4.65414 3.74999 4.87493 3.74999H47.125ZM47.125 36.25H4.87503C3.97844 36.25 3.24999 35.5216 3.24999 34.6249V7.30944L24.9352 26.1033C25.2415 26.3682 25.6207 26.5 26 26.5C26.3793 26.5 26.7585 26.3684 27.0648 26.1033L48.75 7.30944V34.625C48.7499 35.5216 48.0216 36.25 47.125 36.25Z"
                        fill="green"
                      />
                    </svg>
                    {/*  <span className="icon-call-calling">
                      <span className="path1"></span>
                      <span className="path2"></span>
                      <span className="path3"></span>
                      <span className="path4"></span>
                    </span> */}
                  </div>
                  <div className="content">
                    <p>Need help? 24/7</p>

                    <h6>
                      {/* <Link to="tel:0123456678">
                        support@hospitalsinuae.com
                      </Link> */}
                      <a href="mailto:support@hospitalsinuae.com">
                        support@hospitalsinuae.com
                      </a>
                    </h6>
                  </div>
                </div>
                <p>
                  Be the First to Know! Our newsletter is your ticket to staying
                  ahead on healthcare trends, discovering new services, and
                  enjoying exclusive promotions. Don't miss out—subscribe now!
                </p>
                {/*   <div className="ft-icon">
                  {" "}
                  <i className="icon-map-pin"></i> 101 E 129th St, East Chicago,
                  IN 46312, US
                </div> */}
                <form action="#" id="subscribe-form">
                  <input
                    type="email"
                    placeholder="Your email address"
                    required=""
                    id="subscribe-email"
                  />
                  <button
                    className="tf-button"
                    type="submit"
                    id="subscribe-button"
                  >
                    <i className="icon-paper-plane-o"></i>
                  </button>
                </form>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-6">
              <div className="footer-cl-2">
                <h6 className="ft-title">General</h6>
                <ul className="navigation-menu-footer">
                  <li>
                    {" "}
                    <Link to="/">Home</Link>{" "}
                  </li>
                  <li>
                    {" "}
                    <Link to="/aboutus">About us</Link>{" "}
                  </li>
                  <li>
                    {" "}
                    <Link to="/contact-us">Contact us</Link>{" "}
                  </li>
                  {/*   <li>
                    {" "}
                    <Link to="find-jobs-list.html">Jobs & Careers</Link>{" "}
                  </li> */}
                  <li>
                    <Link to="/comming-soon">Media & News</Link>{" "}
                  </li>
                  {/*  <li>
                  {" "}
                  <Link to="employers-grid-fullwidth.html">
                    Employers Grid
                  </Link>{" "}
                </li> */}
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-6">
              <div className="footer-cl-3">
                <h6 className="ft-title">Quick Links</h6>
                <ul className="navigation-menu-footer">
                  <li>
                    {" "}
                    <Link to="/all-category">Speciality/Departments</Link>{" "}
                  </li>
                  <li>
                    {" "}
                    <Link to="/doctors">Doctors</Link>{" "}
                  </li>
                  {/* <li>
                    {" "}
                    <Link to="/comming-soon">Departments</Link>{" "}
                  </li> */}
                  <li>
                    {" "}
                    <Link to="/comming-soon">Insurance</Link>{" "}
                  </li>
                  <li>
                    {" "}
                    <Link to="/comming-soon">Book an Appointment</Link>{" "}
                  </li>
                  {/*  <li>
                  {" "}
                  <Link to="contact-us.html">Contact us </Link>{" "}
                </li> */}
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-6">
              <div className="footer-cl-4">
                <h6 className="ft-title">For Employers</h6>
                <ul className="navigation-menu-footer">
                  <li>
                    {" "}
                    <Link to="/faqs">FAQ</Link>{" "}
                  </li>
                  <li>
                    {" "}
                    <Link to="/comming-soon">Blogs</Link>{" "}
                  </li>
                  <li>
                    {" "}
                    <Link to="/comming-soon">Feedback</Link>{" "}
                  </li>
                  {/*  <li>
                  {" "}
                  <Link to="find-jobs-list.html">Feedback</Link>{" "}
                </li> */}
                  {/*  <li>
                  {" "}
                  <Link to="find-jobs-list.html">Jobs Listing</Link>{" "}
                </li> */}
                  {/*  <li>
                  {" "}
                  <Link to="find-jobs-grid.html">Jobs Style Grid</Link>{" "}
                </li> */}
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-6">
              <div className="footer-cl-5">
                <h6 className="ft-title">Download App</h6>
                <ul className="ft-download">
                  <li>
                    <Link to={"/comming-soon"}>
                      <img
                        src={require("../../../assets/images/review/btn3.png")}
                        alt="images"
                      />
                    </Link>
                  </li>
                  <li>
                    <Link to={"/comming-soon"}>
                      <img
                        src={require("../../../assets/images/review/btn4.png")}
                        alt="images"
                      />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom">
        <div className="tf-container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              {/*  <div className="bt-left">
              <div className="copyright">
                ©2023 Themesflat. All Rights Reserved.
              </div>
              <div className="select-language">
                <div className="dropdown" id="language">
                  <a className="btn-selector nolink input-form">
                    <span>
                      <img
                        src={require("../../assets/images/review/flag.png")}
                        alt=""
                      />
                    </span>
                    English
                  </a>
                </div>
              </div>
            </div> */}
            </div>
            <div className="col-lg-6 col-md-6">
              <ul className="menu-bottom d-flex aln-center">
                <li>
                  <Link to="/termsofuse">Terms Of Services</Link>{" "}
                </li>
                <li>
                  <Link to="/customprivacy">Privacy Policy</Link>{" "}
                </li>
                <li>
                  <Link to="/customcookie">Cookie Policy</Link>{" "}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
