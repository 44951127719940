import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { createContext } from "react";

const DepartmentGlobal = createContext();

const DepartmentContext = ({ children }) => {
  const [departmentContext, setDepartmentContext] = useState([]);
  const [departmentValue, setDepartmentValue] = useState("Select Department");
  const [treatment, setTreatment] = useState("Select Treatment");

  return (
    <DepartmentGlobal.Provider
      value={{
        departmentContext,
        setDepartmentContext,
        departmentValue,
        setDepartmentValue,
        treatment,
        setTreatment,
      }}
    >
      {children}
    </DepartmentGlobal.Provider>
  );
};

export default DepartmentContext;

export const DepartmentGlobalData = () => {
  return useContext(DepartmentGlobal);
};
