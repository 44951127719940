import React, { useEffect } from "react";
import "./CommingSoon.css";
import Header2 from "../../components/header/Header2";
import CustomFooter from "../Footer_pages/Footer/CustomFooter";
import img from "../../assets/images/comimgSoonpng.png";
import WOW from "wowjs";

function CommingSoon() {
  setTimeout(() => {
    window.scroll({
      top: 1,
      left: 0,
      behavior: "smooth",
    });
  }, 100);
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, [<CommingSoon />]);

  return (
    <>
      <Header2 />
      <div
        className="coming-soon-container wow slideInLeft "
        data-wow-delay="0s"
        data-wow-duration="1s"
      >
        <h2>COMING SOON ...</h2>
      </div>

      <CustomFooter />
    </>
  );
}

export default CommingSoon;
