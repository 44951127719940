const dataCate = [
  {
    id: 1,
    title: "Autoimmune Diseases",
    unit: "120 Treatments",
    subs: [
      {
        name: "Lyme Disease",
        category_name: "Lyme Disease",
        route: null,
        color: null,
      },
      {
        name: "Raynaud's syndrome",
        category_name: "Raynaud's syndrome",
        route: null,
        color: null,
      },
      {
        name: "Rheumatoid arthritis",
        category_name: "Rheumatoid arthritis",
        route: null,
        color: null,
      },
      {
        name: "Systemic lupus erythematosus",
        category_name: "Systemic lupus erythematosus",
        route: null,
        color: null,
      },
      {
        name: "Vasculitis",
        category_name: "Vasculitis",
        route: null,
        color: null,
      },
    ],
  },
  {
    id: 2,
    title: "Cancer Treatment",
    unit: "120 Treatments",
    active: "active",
    subs: [
      {
        name: "Lyme Disease",
        category_name: "Lyme Disease",
        route: null,
        color: null,
      },
      {
        name: "Raynaud's syndrome",
        category_name: "Raynaud's syndrome",
        route: null,
        color: null,
      },
      {
        name: "Rheumatoid arthritis",
        category_name: "Rheumatoid arthritis",
        route: null,
        color: null,
      },
      {
        name: "Systemic lupus erythematosus",
        category_name: "Systemic lupus erythematosus",
        route: null,
        color: null,
      },
      {
        name: "Vasculitis",
        category_name: "Vasculitis",
        route: null,
        color: null,
      },
    ],
  },

  {
    id: 3,
    title: "Endocrinology",
    unit: "120 Treatments",
    subs: [
      {
        name: "Lyme Disease",
        category_name: "Lyme Disease",
        route: null,
        color: null,
      },
      {
        name: "Raynaud's syndrome",
        category_name: "Raynaud's syndrome",
        route: null,
        color: null,
      },
      {
        name: "Rheumatoid arthritis",
        category_name: "Rheumatoid arthritis",
        route: null,
        color: null,
      },
      {
        name: "Systemic lupus erythematosus",
        category_name: "Systemic lupus erythematosus",
        route: null,
        color: null,
      },
      {
        name: "Vasculitis",
        category_name: "Vasculitis",
        route: null,
        color: null,
      },
    ],
  },
  {
    id: 4,
    title: "Accounting",
    unit: "120 Treatments",
    subs: [
      {
        name: "Lyme Disease",
        category_name: "Lyme Disease",
        route: null,
        color: null,
      },
      {
        name: "Raynaud's syndrome",
        category_name: "Raynaud's syndrome",
        route: null,
        color: null,
      },
      {
        name: "Rheumatoid arthritis",
        category_name: "Rheumatoid arthritis",
        route: null,
        color: null,
      },
      {
        name: "Systemic lupus erythematosus",
        category_name: "Systemic lupus erythematosus",
        route: null,
        color: null,
      },
      {
        name: "Vasculitis",
        category_name: "Vasculitis",
        route: null,
        color: null,
      },
    ],
  },
  {
    id: 5,
    title: "Customer Service",
    unit: "120 Treatments",
    subs: [
      {
        name: "Lyme Disease",
        category_name: "Lyme Disease",
        route: null,
        color: null,
      },
      {
        name: "Raynaud's syndrome",
        category_name: "Raynaud's syndrome",
        route: null,
        color: null,
      },
      {
        name: "Rheumatoid arthritis",
        category_name: "Rheumatoid arthritis",
        route: null,
        color: null,
      },
      {
        name: "Systemic lupus erythematosus",
        category_name: "Systemic lupus erythematosus",
        route: null,
        color: null,
      },
      {
        name: "Vasculitis",
        category_name: "Vasculitis",
        route: null,
        color: null,
      },
    ],
  },
  {
    id: 6,
    title: "Data Science",
    unit: "120 Treatments",
    subs: [
      {
        name: "Lyme Disease",
        category_name: "Lyme Disease",
        route: null,
        color: null,
      },
      {
        name: "Raynaud's syndrome",
        category_name: "Raynaud's syndrome",
        route: null,
        color: null,
      },
      {
        name: "Rheumatoid arthritis",
        category_name: "Rheumatoid arthritis",
        route: null,
        color: null,
      },
      {
        name: "Systemic lupus erythematosus",
        category_name: "Systemic lupus erythematosus",
        route: null,
        color: null,
      },
      {
        name: "Vasculitis",
        category_name: "Vasculitis",
        route: null,
        color: null,
      },
    ],
  },
  {
    id: 7,
    title: "Engineering",
    unit: "120 Treatments",
    subs: [
      {
        name: "Lyme Disease",
        category_name: "Lyme Disease",
        route: null,
        color: null,
      },
      {
        name: "Raynaud's syndrome",
        category_name: "Raynaud's syndrome",
        route: null,
        color: null,
      },
      {
        name: "Rheumatoid arthritis",
        category_name: "Rheumatoid arthritis",
        route: null,
        color: null,
      },
      {
        name: "Systemic lupus erythematosus",
        category_name: "Systemic lupus erythematosus",
        route: null,
        color: null,
      },
      {
        name: "Vasculitis",
        category_name: "Vasculitis",
        route: null,
        color: null,
      },
    ],
  },
  {
    id: 8,
    title: "IT & Networking",
    unit: "120 Treatments",
    subs: [
      {
        name: "Lyme Disease",
        category_name: "Lyme Disease",
        route: null,
        color: null,
      },
      {
        name: "Raynaud's syndrome",
        category_name: "Raynaud's syndrome",
        route: null,
        color: null,
      },
      {
        name: "Rheumatoid arthritis",
        category_name: "Rheumatoid arthritis",
        route: null,
        color: null,
      },
      {
        name: "Systemic lupus erythematosus",
        category_name: "Systemic lupus erythematosus",
        route: null,
        color: null,
      },
      {
        name: "Vasculitis",
        category_name: "Vasculitis",
        route: null,
        color: null,
      },
    ],
  },
];

export default dataCate;
