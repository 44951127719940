import React from "react";
import emailicon from "../../src/assets/images/email-Icon.png";
import { Link } from "react-router-dom";
function VerifyEmail() {
  return (
    <div className="text-center m-5">
      <h3>Verify Your Email</h3>
      <img src={emailicon} style={{ width: "300px", marginTop: "1px" }} alt="img"/>
      <h6>Verification link has being send to your mail please check....</h6>
      <button className="m-5">
        <Link to="/">Go Back</Link>
      </button>
    </div>
  );
}

export default VerifyEmail;
