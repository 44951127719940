import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css";
import { Link } from "react-router-dom";
import axios from "axios";

HospitalSwiper.propTypes = {};

function HospitalSwiper() {
  const [swiperDatas, setswiperDatas] = useState([]);
  console.log("this== ", swiperDatas);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/resource/Hospital?fields=["hospital_name", "image","name"]`
      );

      console.log("this is slider Details", response.data);
      setswiperDatas(response.data.data);
    } catch (error) {
      console.log("this is Category Details errror", error);
    }
  };
  return (
    <section>
      <div className="wd-partner slider-background">
        <div className="tf-container">
          <h1 className="title-partner">Top Healthcare Providers</h1>

          <Swiper
            modules={[Autoplay]}
            spaceBetween={68.95}
            slidesPerView={6}
            autoplay={{
              delay: 1,
              disableOnInteraction: true,
            }}
            className="partner-type-6"
            loop={true}
            speed={5000}
            breakpoints={{
              320: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              500: {
                slidesPerView: 3,
              },
              800: {
                slidesPerView: 4,
              },
              1200: {
                slidesPerView: 5,
              },
              1600: {
                slidesPerView: 6,
              },
            }}
          >
            {swiperDatas.map((item) => (
              <SwiperSlide key={item.id}>
                <Link
                  to={`/single-hospital/${item?.name}`}
                  className="logo-partner"
                >
                  <img
                    src={`${process.env.REACT_APP_API_URL}${item.image}`}
                    alt={item.hospital_name}
                    className="slider-image"
                  />
                  <div
                    className={
                      item.hospital_name.length > 30
                        ? ` text-white text-center`
                        : `pt-4 text-white text-center`
                    }
                  >
                    <p className="fw-bold">{item.hospital_name}</p>
                  </div>
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
}

export default HospitalSwiper;
