import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Sidebar from "../../components/jobs/Sidebar";
import SortBuy from "../../components/dropdown/SortBuy";
import axios from "axios";
import noImage from "../../assets/images/no-img.jpeg";
import Button from "../../components/button";
import Button2 from "../../components/button/Button2";
import Badge from "react-bootstrap/Badge";
import Preloader from "../../components/preloader";
import Skeleton from "react-loading-skeleton";
import HospitalListingSkeletons from "../../components/sekeletons/HospitalListingSkeletons";
import { GlobalData } from "../../UserContext/userContext";
import { FacilityGlobalData } from "../../UserContext/facilityContext";
import { DepartmentGlobalData } from "../../UserContext/DepartmentContext";
import { Tooltip } from "react-tooltip";

import doctors from "../../assets/fakeData/doctorsList.";

const DoctorsListingData = () => {
  //const { data } = props;
  const [data, setData] = useState([]);
  // console.log("🚀 + HospitalDataAxios + data:", data);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  // const [page1, setPage1] = useState(0);
  // const [limit1, setLimit1] = useState(10);
  const [activePage, setActivePage] = useState(0);
  const [totalHosp, setTotalHosp] = useState(0);
  const [loading, setLoading] = useState(true);
  const [subLoading, setSubLoading] = useState(true);
  const [searchResult, setSearchResult] = useState([
    {
      input: "",
      searchRes: [],
    },
  ]);
  const [treatmentDetails, setTreatmentDetails] = useState([]);
  const [allCategory, setAllCategory] = useState([]);
  // console.log("🚀 + HospitalDataAxios + allCategory:", allCategory);
  //const [dropDowncategory, setDropDowncategory] = useState("Select Treatment");

  const { dropDownLocation, setDropDownLocation } = GlobalData();
  const { facility, setFacility } = FacilityGlobalData();
  const { departmentValue, setDepartmentContext, treatment, setTreatment } =
    DepartmentGlobalData();

  const { category } = useParams();

  useEffect(() => {
    fetchDataDepartment();
    const facility = localStorage.getItem("facilityType");
    const pageLimit = localStorage.getItem("limit");

    if (facility === null && pageLimit === null) {
      // Both facility and pageLimit are null
      setFacility("Hospital");
      setLimit(10);
    } else {
      if (facility !== null) {
        // facility is not null, set the state
        setFacility(facility);
      } else {
        // facility is null, set default value
        setFacility("Hospital");
      }

      if (pageLimit !== null) {
        // pageLimit is not null, set the state
        setLimit(pageLimit);
      } else {
        // pageLimit is null, set default value
        setLimit(10);
      }
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [page, limit, facility, dropDownLocation, departmentValue]);

  useEffect(() => {
    SearchData();
  }, [searchResult?.input]);

  useEffect(() => {
    fetchTreatmentDetails();
  }, [departmentValue]);

  const fetchData = async () => {
    setLoading(true);
    try {
      let url = `${process.env.REACT_APP_API_URL}/api/method/hdir.api.list_hospital`;
      let params = [];

      if (dropDownLocation && dropDownLocation !== "All Location") {
        params.push(`emirate=${dropDownLocation}`);
      }
      if (departmentValue !== "Select Department" && dropDownLocation) {
        params.push(`q=${departmentValue}`);
      }

      if (facility) {
        if (facility === "All Facilities") {
          params.push("");
        } else {
          params.push(`ftype=${facility}`);
        }
      }

      params.push(`limit_start=${page}`);
      params.push(`limit=${limit}`);

      if (params.length > 0) {
        url += `?${params.join("&")}`;
      }

      const response = await axios.get(url);
      setData(response.data.message.values);
      setTotalHosp(response.data.message.count);
    } catch (err) {
      console.log(err);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  const fetchTreatmentDetails = async () => {
    try {
      const mainCategory = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/resource/Category/${departmentValue}` //descrption context
      );

      setAllCategory((prev) => ({
        ...prev,
        descripion: mainCategory.data.data,
      }));
      setAllCategory((prev) => ({
        ...prev,
        subDescripion: "",
      }));
      // setTreatment("Select Treatment");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/resource/Category?filters=[["parent_category","=","${departmentValue}"]]`
      );
      console.log("this is Category Details", response);
      setTreatmentDetails(response.data.data);
    } catch (error) {
      console.log("this is Category Details errror", error);
    }
  };

  useEffect(() => {
    subCategoryDes();
  }, [treatment]);

  const subCategoryDes = async () => {
    if (treatment) {
      try {
        const mainCategory = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/resource/Category/${treatment}`
        );
        setAllCategory((prev) => ({
          ...prev,
          subDescripion: mainCategory.data.data,
        }));
      } catch (error) {
        console.log("this is Category Details errror", error);
      }
    } else {
      console.log("no category name passed");
    }
  };

  //Axios for search result

  const SearchData = async () => {
    if (searchResult?.input?.length > 1) {
      try {
        setLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/method/hdir.api.search?q=${searchResult.input}`
        );
        setSearchResult((prev) => ({
          ...prev,
          searchRes: response.data.message,
        }));
        setTotalHosp(response.data.message.length);
      } catch (err) {
        console.error("There was an error!", err);
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    }
  };

  //Axios for department options

  const fetchDataDepartment = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/method/hdir.api.get_category`
      );

      setDepartmentContext(response.data.message);
    } catch (err) {
      console.log(err, "==this is error");
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  };

  //Pagination Starts here....

  const pageLength = totalHosp;
  const itemsPerPage = 10;
  let pageCount = Math.ceil(pageLength / itemsPerPage);
  let startPage = Math.max(1, activePage - 2);
  let endPage = Math.min(pageCount, startPage + 4);

  let ar = Array.from(
    { length: endPage - startPage + 1 },
    (_, index) => startPage + index
  );

  const handleClick = (index) => {
    setActivePage(index);
    setPage(index * itemsPerPage);
  };

  const handleNavigation = (param) => {
    if (param === "+" && activePage < pageCount - 1) {
      setActivePage((prev) => prev + 1);
      setPage((prev) => prev + itemsPerPage);
    } else if (param === "-" && activePage > 0) {
      setPage((prev) => prev - itemsPerPage);
      setActivePage((prev) => prev - 1);
    }
  };

  //Page Size dropdown..

  const PageOptions = [
    { value: 10, label: "Show 10" },
    { value: 20, label: "Show 20" },
    { value: 50, label: "Show 50" },
    { value: 100, label: "Show 100" },
    { value: 0, label: "Show All" },
  ];
  const handlePageChange = (e) => {
    localStorage.setItem("limit", e.value);
    const pageLimit = localStorage.getItem("limit");
    setLimit(pageLimit);
    // setLimit1(pageLimit);
  };

  const handleFacilityChange = (e) => {
    localStorage.setItem("facilityType", e.value);
    const facilityType = localStorage.getItem("facilityType");
    setFacility(facilityType);
  };
  const handleLocationChange = (e) => {
    localStorage.setItem("location", e);
    const location = localStorage.getItem("location");
    setDropDownLocation(location);
  };
  const handlechange = (e) => {
    setSearchResult((prev) => ({ ...prev, input: e.target.value }));
  };
  return (
    <div>
      <div>
        <section className="inner-employer-section-two">
          <div className="tf-container  hospital-listing-container">
            <div className="row">
              <div className="col-lg-12">
                <div className="group-4-8">
                  <div className="cl4 ">
                    <Sidebar
                      handleFacilityChange={handleFacilityChange}
                      facility={facility}
                      handlechange={handlechange}
                      handleLocationChange={handleLocationChange}
                      dropDownLocation={dropDownLocation}
                      treatmentDetails={treatmentDetails}
                      //dropDowncategory={dropDowncategory}
                      //setDropDowncategory={setDropDowncategory}
                      allCategory={allCategory}
                      setDropDownLocation={setDropDownLocation}
                      setAllCategory={setAllCategory}
                    />
                  </div>
                  <Tabs className="cl8  tf-tab">
                    <div className="wd-meta-select-job">
                      <div className="wd-findjob-filer">
                        <div className="group-select-display">
                          <TabList className="inner menu-tab">
                            <Tab className="btn-display">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="17"
                                height="16"
                                viewBox="0 0 17 16"
                                fill="none"
                              >
                                <path
                                  d="M0.5 12.001L0.5 16.0005C0.880952 16.001 1.09693 16.001 1.83333 16.001L15.1667 16.001C15.9031 16.001 16.5 16.0005 16.5 16.0005L16.5 12.001C16.5 12.001 15.9031 12.001 15.1667 12.001L1.83333 12.001C1.09693 12.001 0.880952 12.001 0.5 12.001Z"
                                  fill="#A0A0A0"
                                ></path>
                                <path
                                  d="M0.5 6.00098L0.5 10.0005C0.880952 10.001 1.09693 10.001 1.83333 10.001L15.1667 10.001C15.9031 10.001 16.5 10.0005 16.5 10.0005L16.5 6.00098C16.5 6.00098 15.9031 6.00098 15.1667 6.00098L1.83333 6.00098C1.09693 6.00098 0.880952 6.00098 0.5 6.00098Z"
                                  fill="#A0A0A0"
                                ></path>
                                <path
                                  d="M0.5 0.000976562L0.5 4.0005C0.880952 4.00098 1.09693 4.00098 1.83333 4.00098L15.1667 4.00098C15.9031 4.00098 16.5 4.0005 16.5 4.0005L16.5 0.000975863C16.5 0.000975863 15.9031 0.000975889 15.1667 0.000975921L1.83333 0.000976504C1.09693 0.000976536 0.880952 0.000976546 0.5 0.000976562Z"
                                  fill="#A0A0A0"
                                ></path>
                              </svg>
                            </Tab>
                            {/* <Tab className="btn-display ">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="17"
                                height="16"
                                viewBox="0 0 17 16"
                                fill="none"
                              >
                                <path
                                  d="M4.5 0H0.500478C0.5 0.380952 0.5 0.596931 0.5 1.33333V14.6667C0.5 15.4031 0.500478 16 0.500478 16H4.5C4.5 16 4.5 15.4031 4.5 14.6667V1.33333C4.5 0.596931 4.5 0.380952 4.5 0Z"
                                  fill="white"
                                ></path>
                                <path
                                  d="M10.5 0H6.50048C6.5 0.380952 6.5 0.596931 6.5 1.33333V14.6667C6.5 15.4031 6.50048 16 6.50048 16H10.5C10.5 16 10.5 15.4031 10.5 14.6667V1.33333C10.5 0.596931 10.5 0.380952 10.5 0Z"
                                  fill="white"
                                ></path>
                                <path
                                  d="M16.5 0H12.5005C12.5 0.380952 12.5 0.596931 12.5 1.33333V14.6667C12.5 15.4031 12.5005 16 12.5005 16H16.5C16.5 16 16.5 15.4031 16.5 14.6667V1.33333C16.5 0.596931 16.5 0.380952 16.5 0Z"
                                  fill="white"
                                ></path>
                              </svg>
                            </Tab> */}
                          </TabList>
                          <p className="nofi-job">
                            <span>{totalHosp}</span> recommended for you
                          </p>
                        </div>
                        <SortBuy
                          PageOptions={PageOptions}
                          handlePageChange={handlePageChange}
                          limit={limit}
                        />
                      </div>
                    </div>
                    <div className="content-tab">
                      {/* <TabPanel className="inner">
                        {searchResult?.input?.length >= 1
                          ? searchResult?.searchRes?.map((idx) => (
                              <div key={idx.id} className="features-job mb1 ">
                                {loading ? (
                                  <HospitalListingSkeletons />
                                ) : (
                                  <>
                                    <div className="job-archive-header  ">
                                      <div>
                                        <div className="row ">
                                          <div className="col-lg-5 hospital-data-container">
                                            {idx.best_choice === 1 && (
                                              <Badge
                                                className="hospital-data-badge"
                                                bg="danger"
                                              >
                                                Best Choice
                                              </Badge>
                                            )}

                                            <div className="hospital-data">
                                              {idx.image ? (
                                                <img
                                                  src={`${process.env.REACT_APP_API_URL}${idx.image}`}
                                                  alt={idx.hospital_name}
                                                  style={{
                                                    width: "310px",
                                                    height: "205px",
                                                    objectFit: "cover",
                                                  }}
                                                />
                                              ) : (
                                                <img
                                                  src={noImage}
                                                  alt={idx.hospital_name}
                                                  style={{
                                                    width: "310px",
                                                    height: "205px",
                                                    objectFit: "cover",
                                                  }}
                                                />
                                              )}
                                            </div>
                                          </div>
                                          <div className="col-lg-7">
                                            <div className="box-content">
                                              <h4>
                                                 <Link
                                            to={`/single-hospital/${idx.name}`}
                                          > 
                                                {idx.facility_type}
                                                 </Link> 
                                              </h4>
                                              <h3>
                                              <Link
                                            to={`/single-hospital/${idx.name}`}
                                          > 
                                                {idx.hospital_name}{" "}
                                                 </Link> 
                                                 <span className="icon-bolt"></span> 
                                              </h3>
                                              <div className="hospital-data-logo">
                                                <div>
                                                  <ul
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "start",
                                                      flexDirection: "column",
                                                    }}
                                                  >
                                                    <li>
                                                      {idx.emirate && (
                                                        <>
                                                          <span className="icon-map-pin px-1" />
                                                          {idx.emirate}
                                                        </>
                                                      )}
                                                    </li>

                                                    {/*  <li>
                                <span className="icon-calendar"></span>
                                {idx.time}
                              </li> 
                                                  </ul>
                                                </div>

                                                <div>
                                                  <ul
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                    }}
                                                  >
                                                    {idx?.hospital_certificate?.map(
                                                      (data, index) =>
                                                        index < 5 && (
                                                          <li key={index}>
                                                            <img
                                                              src={`${process.env.REACT_APP_API_URL}${data?.logo}`}
                                                              alt={`Hospital Certificate ${
                                                                index + 1
                                                              }`}
                                                              style={{
                                                                height: "50px",
                                                                width: "56px",
                                                                marginRight:
                                                                  "2px",
                                                                objectFit:
                                                                  "contain",
                                                              }}
                                                            />
                                                          </li>
                                                        )
                                                    )}
                                                  </ul>
                                                </div>
                                              </div>
                                              {/*   <span className="icon-heart"></span> */}
                      {/* <div className="hospital-data-about pt-1">
                                          {idx.about && (
                                            <div
                                              dangerouslySetInnerHTML={{
                                                __html: idx.about
                                                  .substring(0, 300)
                                                  .replace(/\s+/g, " "),
                                              }}
                                            />
                                          )}
                                        </div> 
                                            </div>
                                            <div className="hospital-data-button">
                                        <Button
                                          title={"view more"}
                                          link={`/single-hospital/${idx.name}`}
                                        />
                                      </div> 
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="job-archive-footer">
                                      {/*  <div className="job-footer-left">
                           <ul className="job-tag">
                            <li>
                              <Link to="#">{idx.jobs1}</Link>
                            </li>
                            <li>
                              <Link to="#">{idx.jobs2}</Link>
                            </li>
                          </ul> 

                            <div className="star">
                            <span className="icon-star-full"></span>
                            <span className="icon-star-full"></span>
                            <span className="icon-star-full"></span>
                            <span className="icon-star-full"></span>
                            <span className="icon-star-full"></span>
                          </div>
                        </div> 
                                        <div className="job-footer-right">
                          <div className="price">
                            <span className="icon-dolar1"></span>
                            <p>
                              {idx.price}{" "}
                              <span className="year">/year</span>
                            </p>
                          </div>
                          <p className="days">22 days left to apply</p>
                        </div> 
                                    </div>
                                  </>
                                )}
                              </div>
                            ))
                          : doctors.map((idx, i) => (
                              <div key={i} className="features-job mb1">
                                {loading ? (
                                  <HospitalListingSkeletons />
                                ) : (
                                  <>
                                    <div className="job-archive-header">
                                      <div>
                                        <div className="row ">
                                          <div className="col-lg-5 hospital-data-container">
                                            {idx.best_choice === 1 && (
                                              <Badge
                                                className="hospital-data-badge"
                                                bg="danger"
                                              >
                                                Best Choice
                                              </Badge>
                                            )}

                                            <div className="hospital-data">
                                              {idx.image ? (
                                                <img
                                                  src={`${idx.image}`}
                                                  alt={idx.image}
                                                  style={{
                                                    width: "310px",
                                                    height: "205px",
                                                    objectFit: "contain",
                                                  }}
                                                />
                                              ) : (
                                                <img
                                                  src={noImage}
                                                  alt={idx.hospital_name}
                                                  style={{
                                                    width: "310px",
                                                    height: "205px",
                                                    objectFit: "cover",
                                                  }}
                                                />
                                              )}
                                            </div>
                                          </div>
                                          <div
                                            className="col-lg-7 "
                                            style={{ position: "relative" }}
                                          >
                                            <div className="box-content">
                                              <h4 className="py-1">
                                                <Link
                                                  to={`/single-hospital/${idx.name}`}
                                                >
                                                  {idx.department}
                                                </Link>
                                              </h4>

                                              <h3 className="py-1">
                                                {idx.name}
                                              </h3>
                                              <div className="hospital-data-logo">
                                                <div>
                                                  <ul className="custom-list">
                                                    <li>
                                                      <span
                                                        className="fw-bold "
                                                        style={{
                                                          marginRight: "7px",
                                                        }}
                                                      >
                                                        {idx.patientComments}
                                                      </span>
                                                      Patient Comments
                                                    </li>
                                                    <li>
                                                      <span
                                                        className="fw-bold "
                                                        style={{
                                                          marginRight: "7px",
                                                        }}
                                                      >
                                                        {
                                                          idx.patientSatisfaction
                                                        }
                                                      </span>
                                                      Patient Satisfaction
                                                    </li>

                                                    <li>
                                                      <span className="icon-map-pin px-1"></span>
                                                      {idx.location}
                                                    </li>
                                                  </ul>
                                                </div>

                                                <div className="star px-1">
                                                  <span className="icon-star-full disable"></span>
                                                  <span className="icon-star-full disable"></span>
                                                  <span className="icon-star-full disable"></span>
                                                  <span className="icon-star-full disable"></span>
                                                  <span className="icon-star-full disable"></span>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="hospital-data-button">
                                              <button className="doctors-apply-button">
                                                REQUEST AN APPOINTMENT
                                                <span className="icon-keyboard_arrow_right"></span>
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="job-archive-footer">
                                      {/*  <div className="job-footer-left">
                           <ul className="job-tag">
                            <li>
                              <Link to="#">{idx.jobs1}</Link>
                            </li>
                            <li>
                              <Link to="#">{idx.jobs2}</Link>
                            </li>
                          </ul> 

                            <div className="star">
                            <span className="icon-star-full"></span>
                            <span className="icon-star-full"></span>
                            <span className="icon-star-full"></span>
                            <span className="icon-star-full"></span>
                            <span className="icon-star-full"></span>
                          </div>
                        </div> 
                                        <div className="job-footer-right">
                          <div className="price">
                            <span className="icon-dolar1"></span>
                            <p>
                              {idx.price}{" "}
                              <span className="year">/year</span>
                            </p>
                          </div>
                          <p className="days">22 days left to apply</p>
                        </div> 
                                    </div>
                                  </>
                                )}
                              </div>
                            ))}
                      </TabPanel> */}
                      <TabPanel className="inner">
                        <div className="group-col-2">
                          {doctors?.map((idx) => (
                            <div className="features-job cl2 single-page-hospital-grid">
                              <div className="job-archive-header ">
                                <div className="inner-box">
                                  {/* {idx.best_choice === 1 && (
                                    <Badge
                                      className="single-page-hospital-badge"
                                      bg="danger"
                                    >
                                      Best Choice
                                    </Badge>
                                  )} */}
                                  <div className="logo-company">
                                    {idx.image ? (
                                      <img
                                        src={`${idx.image}`}
                                        alt={idx.name}
                                        style={{
                                          minWidth: "120px",
                                          height: "165px",
                                          objectFit: "contain",
                                        }}
                                      />
                                    ) : (
                                      <img
                                        src={noImage}
                                        alt={idx.name}
                                        style={{
                                          minWidth: "100px",
                                          height: "100px",
                                          objectFit: "cover",
                                        }}
                                      />
                                    )}
                                  </div>
                                  <div className="box-content">
                                    <h4>
                                      <Link to={`/single-doctor`}>
                                        {idx.department}
                                      </Link>
                                    </h4>
                                    <h3>
                                      <Link to={`/single-doctor`}>
                                        {idx.name}
                                      </Link>
                                      {/* <span className="icon-bolt"></span> */}
                                    </h3>
                                    <ul className="d-flex align-items-start flex-column">
                                      <li>
                                        <span className=""></span>
                                        {idx.hospital}
                                      </li>
                                      {/* <li>
                                        <span
                                          className="fw-bold "
                                          style={{
                                            marginRight: "7px",
                                          }}
                                        >
                                          {idx.patientComments}
                                        </span>
                                        Patient Comments
                                      </li>
                                      <li>
                                        <span
                                          className="fw-bold "
                                          style={{
                                            marginRight: "7px",
                                          }}
                                        >
                                          {idx.patientSatisfaction}
                                        </span>
                                        Patient Satisfaction
                                      </li> */}
                                      <li>
                                        <span
                                          className="icon-map-pin  "
                                          style={{ marginRight: "5px" }}
                                        ></span>
                                        {idx.location}
                                      </li>
                                    </ul>
                                    <div className="star px-1">
                                      <span className="icon-star-full disable"></span>
                                      <span className="icon-star-full disable"></span>
                                      <span className="icon-star-full disable"></span>
                                      <span className="icon-star-full disable"></span>
                                      <span className="icon-star-full disable"></span>
                                    </div>

                                    {/*  <span className="icon-heart "></span> */}
                                  </div>
                                </div>
                              </div>
                              {/* <div className="job-archive-footer">
                                <div className="job-footer-left">
                                  <ul className="job-tag">
                                    <li>
                                      <Link to="#">{idx.jobs1}</Link>
                                    </li>
                                    <li>
                                      <Link to="#">{idx.jobs2}</Link>
                                    </li>
                                  </ul>
                                  <div className="star">
                                    <span className="icon-star-full"></span>
                                    <span className="icon-star-full"></span>
                                    <span className="icon-star-full"></span>
                                    <span className="icon-star-full"></span>
                                    <span className="icon-star-full"></span>
                                  </div>
                                </div>
                                <div className="job-footer-right">
                                  <div className="price">
                                    <span className="icon-dolar1"></span>
                                    <p>
                                      {idx.price}
                                      <span className="year">/year</span>
                                    </p>
                                  </div>
                                  <p className="days">{idx.apply}</p>
                                  <Button
                                    title={"view more"}
                                    link={`/single-hospital/${idx.name}`}
                                  />{" "}
                                </div>
                              </div> */}
                              <Link
                                to={`/single-doctor`}
                                className="jobtex-link-item"
                                tabIndex="0"
                              ></Link>
                            </div>
                          ))}
                        </div>
                      </TabPanel>
                    </div>
                  </Tabs>
                </div>

                <ul className="pagination-job p-top">
                  <li onClick={() => handleNavigation("-")}>
                    <Link to="#">
                      <i className="icon-keyboard_arrow_left"></i>
                    </Link>
                  </li>

                  {ar.map((pageNumber) => (
                    <li
                      key={pageNumber}
                      className={pageNumber === activePage + 1 ? "current" : ""}
                      onClick={() => handleClick(pageNumber - 1)}
                    >
                      <Link to="#">{pageNumber}</Link>
                    </li>
                  ))}

                  <li onClick={() => handleNavigation("+")}>
                    <Link to="#">
                      <i className="icon-keyboard_arrow_right"></i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default DoctorsListingData;
