import React, { Children, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import OtpInput from "react-otp-input";
import { checkOTP, register } from "../../../Services/auth.services";

const RegisterModal = ({
  show,
  setShow,
  regSuccessMsg,
  setRegSuccessMsg,
  ...children
}) => {
  const [otp, setOtp] = useState("");
  const [response, setResponse] = useState({
    class: "",
    msg: "",
  });

  console.log(otp, "opt");

  let details = children.NewRegDetails;
  const handleClose = () => (
    setShow(false), setOtp(""), setResponse(""), setRegSuccessMsg(false)
  );

  const handleSubmit = async () => {
    try {
      const response = await checkOTP(details, otp);
      //console.log(response.msg, " response");
      //setResponse(response);
      if (response === "no user found") {
        const regResponse = await register(details);

        if (regResponse === "Email id already exists !") {
          //setResponse(regResponse);
          setResponse((prev) => ({
            ...prev,
            msg: regResponse,
            class: "text-danger",
          }));
        } else if (regResponse === "Registration successful!") {
          //setResponse(regResponse);
          setResponse((prev) => ({
            ...prev,
            msg: `${regResponse} Your email confirmation link has send to your mail`,
            class: "text-sucess",
          }));
          setRegSuccessMsg(true);

          // setTimeout(() => {
          //   setShow(false);
          // }, 2000);
        }
      } else if (response === "Invalid OTP") {
        /* const msg = "Registration failed. Please try again later."; */
        setResponse((prev) => ({
          ...prev,
          msg: response,
          class: "text-danger",
        }));
        //setResponse(msg);
      } else if (response === "OTP VERIFIED & LOGGING IN") {
        const msg = "Mobile number already exist";
        //setResponse(msg);
        setResponse((prev) => ({
          ...prev,
          msg: msg,
          class: "text-danger",
        }));
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        centered="true"
        size="sm"
        className="register-modal-container"
      >
        <Modal.Header closeButton>
          <Modal.Title className="register-modal-title">
            <p>Enter OTP</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {regSuccessMsg && (
            <>
              <div>
                <p className={`${response.class} register-modal-error`}>
                  {response.msg}
                </p>
              </div>
            </>
          )}

          {!regSuccessMsg && (
            <>
              <div>
                <p className={`${response.class} register-modal-error`}>
                  {response.msg}
                </p>
              </div>
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={4}
                renderSeparator={<span>-</span>}
                renderInput={(props) => (
                  <input
                    {...props}
                    style={{
                      width: "50px",
                      margin: "0 5px",
                      padding: "0 0 0 20px",
                    }}
                  />
                )}
              />
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>

          {!regSuccessMsg && (
            <Button variant="primary" onClick={handleSubmit}>
              Submit OTP
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default RegisterModal;
