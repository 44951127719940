import React, { useContext, useState } from "react";
import { createContext } from "react";

const Global = createContext();

function UserContext({ children }) {
  const [mapData, setMapData] = useState([0, 0]);
  const [dropDownLocation, setDropDownLocation] = useState("All Location");
  //console.log("🚀 + UserContext + dropDownLocation:", dropDownLocation);

  return (
    <Global.Provider
      value={{ setMapData, mapData, dropDownLocation, setDropDownLocation }}
    >
      {children}
    </Global.Provider>
  );
}

export default UserContext;

export const GlobalData = () => {
  return useContext(Global);
};
