import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "../button";
import { Link } from "react-router-dom";
import axios from "axios";
import Preloader from "../preloader";
import Skeleton from "react-loading-skeleton";
import { DepartmentGlobalData } from "../../UserContext/DepartmentContext";
import cardio from "../../assets/images/department/b.png";
import Autoimmune from "../../assets/images/department/f.png";
import Cancer from "../../assets/images/department/c.png";
import Endocrinology from "../../assets/images/department/k.png";
import Gastro from "../../assets/images/department/d.png";
import Immuno from "../../assets/images/department/f.png";
import Neurology from "../../assets/images/department/g.png";
import neurosurgery from "../../assets/images/department/6.png";
import eye from "../../assets/images/department/k.png";
import ortho from "../../assets/images/department/h.png";
import otor from "../../assets/images/department/i.png";
import spinal from "../../assets/images/department/j.png";

HospitalCategory.propTypes = {};

function HospitalCategory(props) {
  const { data } = props;
  const { className } = props;
  const [categoryData, setCategoryData] = useState([]);
  const [loading, setLoading] = useState(true);
  const {
    departmentContext,
    setDepartmentContext,
    setDepartmentValue,
    setTreatment,
  } = DepartmentGlobalData();
  // console.log("🚀 + HospitalCategory + departmentContext:", departmentContext);
  //console.log(categoryData, "==categoryData");

  const [dataBlock] = useState({
    title: "Explore By Speciality",
    text: "Discover Specialized Care",
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/method/hdir.api.get_category`
      );
      setCategoryData(response.data.message);
      setDepartmentContext(response.data.message);
    } catch (err) {
      console.log(err, "==this is error");
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  };

  let logoPng = [
    Autoimmune,
    Cancer,
    cardio,
    Endocrinology,
    Gastro,
    Immuno,
    Neurology,
    neurosurgery,
    eye,
    ortho,
    otor,
    spinal,
  ];
  function truncate(source, size) {
    return source.length > size ? source.slice(0, size - 1) + "…" : source;
  }
  return (
    <div>
      <section className={className}>
        <div className="tf-container">
          <div className="row">
            {/*   {loading && } */}
            <div className="col-md-12">
              <div className="tf-title">
                <div className="group-title">
                  <h1>{dataBlock.title}</h1>
                  <p>{dataBlock.text}</p>
                </div>
                <Button title="All Categories" link="/all-category" />
              </div>
            </div>

            <div className="col-md-12">
              <div
                className="group-category-job wow fadeInUp relative"
                data-wow-delay=".2s"
              >
                {categoryData?.slice(0, 8).map((idx, index) => (
                  <div
                    key={idx.id}
                    className={`job-category-box ${idx.active}`}
                    style={{
                      display: "grid",
                      gridTemplateColumns:
                        "repeat(auto-fill, minmax(300px, 1fr))",

                      backgroundImage: `url(${logoPng[index]})`,
                      backgroundSize: "59px 59px",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "250px 14rem",
                    }}
                  >
                    {loading ? (
                      <>
                        <Skeleton count={1} width={100} height={40} />
                        <Skeleton count={5} />
                      </>
                    ) : (
                      <>
                        <div className="job-category-header custom-category-box absolute">
                          <h1>
                            <Link
                              to={`/treatment/${idx.category_name}`}
                              onClick={() =>
                                setDepartmentValue(idx.category_name)
                              }
                            >
                              {idx.category_name}
                            </Link>
                          </h1>
                          {/*  <small className="">{idx.unit}</small> */}
                          <ul className="list-dot mt-3">
                            {idx.subs.slice(0, 6).map((sub) => (
                              <li>
                                <Link
                                  /* to={`/disease/${sub.category_name}`} */
                                  to={`/hospital-list/All Location`}
                                  onClick={() => {
                                    setDepartmentValue(idx.category_name);
                                    setTreatment(sub.category_name);
                                  }}
                                >
                                  {truncate(sub.category_name, 27)}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Link
                            to={`/treatment/${idx.category_name}`}
                            className="btn-category-job explore-category"
                          >
                            <a href=""> Explore</a>
                            <span className="icon-keyboard_arrow_right"></span>
                          </Link>
                          {/* <img
                            src={`${logoPng[index]}`}
                            alt=""
                            // width={"50px"}
                            style={{ maxWidth: "70px", objectFit: "contain" }}
                          /> */}
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default HospitalCategory;
