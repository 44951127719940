import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import SelectLocation from "../dropdown";
import CircleProgess from "../progressBar";
import axios from "axios";
import doctorBannerImg from "../../assets/images/thumb5.87dfa384116832d648ee.png";
import { GlobalData } from "../../UserContext/userContext";

import { FacilityGlobalData } from "../../UserContext/facilityContext";
import { DepartmentGlobalData } from "../../UserContext/DepartmentContext";
import { FaRegHospital } from "react-icons/fa";

Banner04.propTypes = {};

function Banner04(props) {
  const [input, setInput] = useState("");
  const [showSuggestion, setShowSuggestion] = useState(false);
  const [suggestionsData, setSuggestionsData] = useState([]);
  const { dropDownLocation, setDropDownLocation } = GlobalData();
  const { facility, setFacility } = FacilityGlobalData();
  const { setDepartmentValue, setTreatment } = DepartmentGlobalData();

  const handleChange = (e) => {
    setInput((prev) => ({ ...prev, value: e.target.value }));
    if (e.target.value.length >= 1) {
      setShowSuggestion(true);
    }
    if (e.target.value.length < 2 || suggestionsData.length === 0) {
      setShowSuggestion(false);
    }
    return;
  };

  //Axios for search bar....
  useEffect(() => {
    fetchData();
  }, [input]);

  const fetchData = async () => {
    if (input.value.length > 1) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/method/hdir.api.search?q=${input.value}`
        );
        setSuggestionsData(response.data.message);
      } catch (err) {
        console.error("There was an error!", err);
      }
    } else {
    }
  };
  const handleFacilityChange = (e) => {
    localStorage.setItem("facilityType", e.value);
    const facilityType = localStorage.getItem("facilityType");
    setFacility(facilityType);
  };
  const handleLocationChange = (e) => {
    localStorage.setItem("location", e);
    const location = localStorage.getItem("location");
    setDropDownLocation(location);
  };

  const options = [
    { value: "All Location", label: "All Location" },
    { value: "Dubai", label: "Dubai" },
    { value: "Ajman", label: "Ajman" },
    { value: "Abu Dhabi", label: "Abu Dhabi" },
    { value: "Sharjah", label: "Sharjah" },
    { value: "Umm Al Quwain", label: "Umm Al Quwain" },
    { value: "Ras Al Khaimah", label: "Ras Al Khaimah" },
    { value: "Fujairah", label: "Fujairah" },
  ];

  const select1 = [
    { value: "All Facilities", label: "All Facilities" },
    { value: "Hospital", label: "Hospital" },
    { value: "Clinic", label: "Clinic" },
    { value: "Medical Lab", label: "Medical Lab" },
    { value: "Company", label: "Company" },
    { value: "Pharmacy", label: "Pharmacy" },
  ];

  return (
    <section className="tf-slider sl3 over-flow-hidden">
      <div className="tf-container">
        <div className="row">
          <div className="col-lg-10 col-md-12">
            <div className="content wow fadeInUp">
              <div className="heading">
                <h3 className="text-white">
                  Find trusted healthcare providers in our directory
                </h3>
                {/* <p className="text-white">
                  Explore our comprehensive healthcare business directory to
                  discover trusted providers in your area..
                </p> */}
              </div>
              <div className="form-sl">
                <form action="/job-list-sidebar">
                  <div className="row-group-search home1 st">
                    <div className="form-group-1">
                      <span className="icon-search search-job"></span>
                      <input
                        type="text"
                        className="input-filter-search"
                        placeholder="Disease treatment or hospital"
                        //onChange={(e) => handleChange(e)}
                      />
                    </div>
                    <div className="form-group-2">
                      {/* <span className="icon-filter"></span> */}
                      <span>
                        <FaRegHospital />
                      </span>
                      <SelectLocation
                        value={facility}
                        options={select1}
                        onChange={(e) => handleFacilityChange(e)}
                      />
                    </div>
                    <div className="form-group-2">
                      <span className="icon-map-pin"></span>
                      <SelectLocation
                        value={dropDownLocation}
                        options={options}
                        onChange={(e) => handleLocationChange(e.value)}
                      />
                    </div>
                    <div className="form-group-4">
                      <Link to={`/hospital-list/${dropDownLocation}`}>
                        <button
                          type="submit"
                          className="btn btn-find"
                          onClick={() => {
                            setDepartmentValue("Select Department");
                            setTreatment("Select Treatment");
                          }}
                        >
                          Search
                        </button>
                      </Link>
                    </div>
                  </div>
                  {showSuggestion && (
                    <div className="search-suggestion">
                      <ul>
                        {suggestionsData.map((data, index) => (
                          <li key={index}>
                            <Link to={`/single-hospital/${data.name}`}>
                              {data.hospital_name}
                            </Link>{" "}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </form>
              </div>
              <div className="d-flex">
                <ul className="list-category text-white">
                  <li style={{ fontSize: "15px" }}>
                    Recent Hopital Searches :
                  </li>
                  <li>
                    <Link
                      to="hospital-list/All Location"
                      style={{ fontSize: "13px" }}
                    >
                      Immunotherapy for cancer
                    </Link>
                  </li>
                  <li className="">
                    <Link
                      to="hospital-list/All Location"
                      style={{ fontSize: "13px" }}
                    >
                      Dendritic cell therapy
                    </Link>
                  </li>
                </ul>

                <ul
                  className="list-category text-white "
                  style={{ marginLeft: "10px" }}
                >
                  <li style={{ fontSize: "15px" }}>
                    Recent Treatment Searches :
                  </li>

                  <li>
                    <Link
                      to="hospital-list/All Location"
                      style={{ fontSize: "13px" }}
                    >
                      Colorectal cancer
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="hospital-list/All Location"
                      style={{ fontSize: "13px" }}
                    >
                      Vasculitis
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="wd-review-job thumb2 widget-counter tf-sl3">
              <div className="thumb">
                <div className="box1">
                  <img src={doctorBannerImg} alt="images" />
                  <div className="markk ani3">
                    <img
                      src={require("../../assets/images/review/shape3.png")}
                      alt="images"
                    />
                  </div>
                </div>
              </div>
              <div className="tes-box ani5">
                <div className="client-box">
                  <div className="avt">
                    <img
                      src={require("../../assets/images/review/client.jpg")}
                      alt="images"
                    />
                    <div className="badge"></div>
                  </div>
                  <div className="content">
                    <h6 className="number wrap-counter">1M+ Searches</h6>
                    <div className="des">Healthcare Providers</div>
                  </div>
                </div>
              </div>
              <div className="icon1 ani3">
                <img
                  src={require("../../assets/images/review/icon2.png")}
                  alt="images"
                />
              </div>
              <div className="icon2 ani4">
                <img
                  src={require("../../assets/images/review/icon3.png")}
                  alt="images"
                />
              </div>
              <div className="icon3 ani6">
                <img
                  src={require("../../assets/images/review/icon11.png")}
                  alt="images"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Banner04;
