import d1 from "../images/doctors/doctor_1_carousel.jpg";
import d2 from "../images/doctors/doctor_2_carousel.jpg";
import d3 from "../images/doctors/doctor_3_carousel.jpg";
import d4 from "../images/doctors/doctor_4_carousel.jpg";
import d5 from "../images/doctors/doctor_5_carousel.jpg";
import d6 from "../images/doctors/doctor_listing_1.jpg";
import d7 from "../images/doctors/doctor_listing_2.jpg";
import d8 from "../images/doctors/doctor_listing_3.jpg";
import d9 from "../images/doctors/doctor_listing_4.jpg";
import d10 from "../images/doctors/doctor_listing_5.jpg";
const doctors = [
  {
    name: "Dr. Ahmed Ali",
    image: d1,
    rating: 4.5,
    patientComments: 152,
    patientSatisfaction: 93,
    department: "Cardiology",
    location: "Dubai",
    hospital: "Dubai Hospital",
  },
  {
    name: "Dr. Fatima Hassan",
    image: d2,
    rating: 4.7,
    patientComments: 189,
    patientSatisfaction: 96,
    department: "Neurology",
    location: "Abu Dhabi",
    hospital: "Sheikh Khalifa Medical City",
  },
  {
    name: "Dr. Mohammed Saeed",
    image: d3,
    rating: 4.2,
    patientComments: 134,
    patientSatisfaction: 89,
    department: "Pediatrics",
    location: "Sharjah",
    hospital: "Al Qassimi Hospital",
  },
  {
    name: "Dr. Aisha Omar",
    image: d4,
    rating: 4.8,
    patientComments: 210,
    patientSatisfaction: 98,
    department: "Dermatology",
    location: "Dubai",
    hospital: "Rashid Hospital",
  },
  {
    name: "Dr. Youssef Khalid",
    image: d5,
    rating: 4.3,
    patientComments: 145,
    patientSatisfaction: 90,
    department: "Orthopedics",
    location: "Ajman",
    hospital: "Sheikh Khalifa Hospital",
  },
  {
    name: "Dr. Layla Al Mansoori",
    image: d6,
    rating: 4.6,
    patientComments: 175,
    patientSatisfaction: 94,
    department: "Gynecology",
    location: "Fujairah",
    hospital: "Fujairah Hospital",
  },
  {
    name: "Dr. Rashid Noor",
    image: d7,
    rating: 4.5,
    patientComments: 160,
    patientSatisfaction: 91,
    department: "Cardiology",
    location: "Ras Al Khaimah",
    hospital: "Saqr Hospital",
  },
  {
    name: "Dr. Salma Yasin",
    image: d8,
    rating: 4.7,
    patientComments: 182,
    patientSatisfaction: 95,
    department: "Endocrinology",
    location: "Dubai",
    hospital: "Latifa Hospital",
  },
  {
    name: "Dr. Omar Jassim",
    image: d9,
    rating: 4.4,
    patientComments: 140,
    patientSatisfaction: 88,
    department: "Gastroenterology",
    location: "Abu Dhabi",
    hospital: "Cleveland Clinic Abu Dhabi",
  },
  {
    name: "Dr. Huda Mahmood",
    image: d10,
    rating: 4.8,
    patientComments: 200,
    patientSatisfaction: 97,
    department: "Pulmonology",
    location: "Dubai",
    hospital: "American Hospital Dubai",
  },
  {
    name: "Dr. Ibrahim Zayed",
    image: "https://via.placeholder.com/150",
    rating: 4.5,
    patientComments: 150,
    patientSatisfaction: 92,
    department: "Ophthalmology",
    location: "Sharjah",
    hospital: "University Hospital Sharjah",
  },
  {
    name: "Dr. Nadia Hussein",
    image: "https://via.placeholder.com/150",
    rating: 4.6,
    patientComments: 170,
    patientSatisfaction: 93,
    department: "Psychiatry",
    location: "Ajman",
    hospital: "Ajman Specialty General Hospital",
  },
  {
    name: "Dr. Khalifa Al Zarooni",
    image: "https://via.placeholder.com/150",
    rating: 4.3,
    patientComments: 138,
    patientSatisfaction: 87,
    department: "Urology",
    location: "Ras Al Khaimah",
    hospital: "Ras Al Khaimah Hospital",
  },
  {
    name: "Dr. Zainab Al Qassimi",
    image: "https://via.placeholder.com/150",
    rating: 4.7,
    patientComments: 190,
    patientSatisfaction: 96,
    department: "Oncology",
    location: "Dubai",
    hospital: "Zulekha Hospital",
  },
  {
    name: "Dr. Hamad Al Sharif",
    image: "https://via.placeholder.com/150",
    rating: 4.2,
    patientComments: 130,
    patientSatisfaction: 88,
    department: "Rheumatology",
    location: "Fujairah",
    hospital: "Thumbay Hospital Fujairah",
  },
  {
    name: "Dr. Amira Nasser",
    image: "https://via.placeholder.com/150",
    rating: 4.5,
    patientComments: 155,
    patientSatisfaction: 91,
    department: "Nephrology",
    location: "Abu Dhabi",
    hospital: "Mafraq Hospital",
  },
  {
    name: "Dr. Majid Al Farsi",
    image: "https://via.placeholder.com/150",
    rating: 4.6,
    patientComments: 165,
    patientSatisfaction: 92,
    department: "Hematology",
    location: "Sharjah",
    hospital: "NMC Royal Hospital",
  },
  {
    name: "Dr. Sara Al Suwaidi",
    image: "https://via.placeholder.com/150",
    rating: 4.7,
    patientComments: 178,
    patientSatisfaction: 94,
    department: "Neurology",
    location: "Ajman",
    hospital: "Sheikh Khalifa Hospital",
  },
  {
    name: "Dr. Faisal Al Balooshi",
    image: "https://via.placeholder.com/150",
    rating: 4.8,
    patientComments: 205,
    patientSatisfaction: 97,
    department: "Dermatology",
    location: "Dubai",
    hospital: "Medcare Hospital",
  },
  {
    name: "Dr. Mariam Al Shamsi",
    image: "https://via.placeholder.com/150",
    rating: 4.4,
    patientComments: 142,
    patientSatisfaction: 89,
    department: "Pediatrics",
    location: "Ras Al Khaimah",
    hospital: "RAK Hospital",
  },
];

export default doctors;
