const dataFaq = [
  {
    id: 1,
    title: "What is Hospitals I N?",
    content:
      "Hospitals I N is an online platform designed to connect users with caregivers across the Middle East and Northern Africa (MENA) region. We provide a convenient and accessible way to find, compare, and engage with healthcare providers, including hospitals, clinics, and individual practitioners.",
    show: "show",
  },
  {
    id: 2,
    title: "How does Hospitals I N work?",
    content:
      "Hospitals I N simplifies the process of finding healthcare providers by allowing users to search based on criteria such as specialty, location, and user ratings. Users can then view detailed profiles of caregivers, including services offered, contact information, and patient reviews. The platform also facilitates appointment booking and online consultations for added convenience.",
  },
  {
    id: 3,
    title: "What types of caregivers can I find on Hospitals I N?",
    content:
      "Hospitals I N features a wide range of healthcare providers, including specialists, primary care physicians, clinics, hospitals, and other medical professionals across various specialties and disciplines.",
  },
  {
    id: 4,
    title:
      "How can I book an appointment with a caregiver through Hospitals I N?",
    content:
      "Booking appointments with caregivers on Hospitals I N is simple. Once you've found a suitable provider, you can easily schedule an appointment through the platform. Choose your preferred date and time, and follow the prompts to confirm your appointment. For online consultations, select the virtual appointment option and follow the instructions to connect with your caregiver remotely.",
  },
  {
    id: 5,
    title: "Can I leave reviews for caregivers on Hospitals I N?",
    content:
      "Yes, Hospitals I N encourages users to share their experiences by leaving reviews for caregivers they have visited. Your feedback helps other users make informed decisions and contributes to the transparency and quality of our platform.",
  },
  {
    id: 6,
    title: "Is Hospitals I N available in multiple languages?",
    content:
      "At present, Hospitals I N is available in [list languages], with plans to expand language support in the future to better serve our diverse user base across the MENA region.",
  },
  /*  {
    id: 6,
    title: "How can I contact a patient? ?",
    content:
      "You can contact a patient by calling the main hospital number and asking to be connected to their room. Please note that patient information is kept confidential, and we will only connect calls to patients who have provided permission.",
  },
  {
    id: 7,
    title: "How can I provide feedback or file a complaint ?",
    content:
      "We value your feedback. You can provide feedback or file a complaint by contacting our Patient Relations department. Contact information can be found on our Contact Us page.",
  },
  {
    id: 8,
    title: " How can I apply for a job at the hospital??",
    content:
      "Job opportunities are listed on our Careers page. You can browse open positions, submit your application online, and learn more about our hiring process.",
  }, */
];
export default dataFaq;
