import React, { useState } from "react";
import { Link } from "react-router-dom";
import img from "../../assets/images/review/google.png";
import img2 from "../../assets/images/review/tweet.png";
import { useForm } from "react-hook-form";
import { login } from "../../Services/auth.services";
import ModalOtp from "../../pages/Login&Reg_page/Login_Page/ModalOtp";
import { Tab, TabList } from "react-tabs";
function SignIn() {
  const [showPass, setShowPass] = useState(false);
  const [show, setShow] = useState(false);
  const [errorVal, setError] = useState("");
  console.log(errorVal);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ mode: "all" });
  const formData = watch();

  const formSubmit = (formData) => {
    //console.log(formData);
    login(formData, setError);
  };
  return (
    <section className="account-section">
      <div className="tf-container">
        <div className="row">
          <div className="wd-form-login">
            {/* <h4>Log In</h4> */}
            {/* <div className="nofi-form">
              <p>
                Username: <span>candidate</span> or <span>employer</span>
              </p>
              <p>
                Password: <span>jobtex</span>
              </p>
              <p>
                <span>Click here if you are an hospital User to Login</span>
              </p>
              
            </div> */}
            <TabList className="menu-tab">
              <Tab className="ct-tab">Individual</Tab>
              <Tab className="ct-tab">
                <Link
                  to="https://hadmin.prismaticsoft.com/auth-login"
                  target="_blank"
                >
                  Facility Manager
                </Link>
              </Tab>
            </TabList>

            <form
              /*  action="/" */
              onSubmit={handleSubmit(formSubmit)}
              autoComplete="off"
            >
              <div>
                {errorVal && (
                  <p className="text-danger text-center">{errorVal}</p>
                )}
              </div>

              <div className="ip">
                <label>
                  Email address<span>*</span>
                </label>
                {errors.email && (
                  <p className="login-error">{errors.email.message}</p>
                )}
                <input
                  type="email"
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                      message: "Invalid email address format",
                    },
                  })}
                  placeholder="Enter your email address"
                />
              </div>
              <div className="ip">
                <label>
                  Password<span>*</span>
                </label>
                {errors.password && (
                  <p className="login-error-password">
                    {errors.password.type === "required" &&
                      "Password is required."}
                    {errors.password.type === "minLength" &&
                      "Password must be at least 5 characters long."}
                    {errors.password.type === "pattern" &&
                      "Password must contain at least one digit and one special character."}
                  </p>
                )}
                <div className="inputs-group auth-pass-inputgroup">
                  <input
                    type={showPass ? "text" : "password"}
                    className="input-form password-input"
                    placeholder="Password"
                    id="password-input"
                    {...register("password", {
                      required: true,
                      minLength: 5,
                      pattern:
                        /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{5,}$/,
                    })}
                  />

                  <Link
                    className={`password-addon ${
                      showPass ? "icon-eye " : "icon-eye-off"
                    }`}
                    id="password-addon"
                    onClick={() => setShowPass(!showPass)}
                  />
                </div>
              </div>

              <div>
                <button type="submit">Login</button>
              </div>
              <div className="group-ant-choice mt-3">
                <div className="sub-ip">
                  <input type="checkbox" />
                  Remember me
                </div>
                <Link to="#" className="forgot" onClick={() => setShow(true)}>
                  Login with OTP
                </Link>
                <Link to="#" className="forgot">
                  Fogot password?
                </Link>
              </div>
              <p className="line-ip">
                <span>or sign up with</span>
              </p>
              <Link to="#" className="btn-social">
                Continue with Facebook
              </Link>
              <Link to="#" className="btn-social">
                <img src={img} alt="images" /> Continue with Google
              </Link>
              <Link to="#" className="btn-social">
                <img src={img2} alt="images" /> Continue with Twitter
              </Link>

              <div className="sign-up">
                Not registered yet? <Link to="/createaccount">Sign Up</Link>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ModalOtp show={show} setShow={setShow} />
    </section>
  );
}

export default SignIn;
