import React, { useState } from "react";
import PropTypes from "prop-types";
import CountUp from "react-countup";
import { Waypoint } from "react-waypoint";
import { Link } from "react-router-dom";
import img1 from "../../assets/images/review/thumb2.png";
import shape1 from "../../assets/images/review/shape.png";
import client1 from "../../assets/images/review/client.jpg";
import icon1 from "../../assets/images/review/icon1.png";
import icon2 from "../../assets/images/review/icon2.png";
import icon3 from "../../assets/images/review/icon3.png";
import about from "../../assets/images/about.jpeg";
import insuranceimage from "../../assets/images/Insurance/adnic.jpg";
function CertificateReview(props) {
  const [viewPortEntered, setViewPortEntered] = useState(false);

  const onVWEnter = () => {
    setViewPortEntered(true);
  };

  return (
    <section className="inner-review-section">
      <div className="tf-container">
        <div className="row">
          <div className="col-lg-7 col-md-12">
            <div className="wd-review-job thumb2 widget-counter stc">
              <div className="thumb" style={{ marginLeft: "-459px" }}>
                <img src={insuranceimage} alt="images" />
              </div>
              {/* <div className="trader-box">
                <div className="content">
                  <h3 className="number wrap-counter">
                    <Waypoint onEnter={onVWEnter}>
                      <span>
                        {viewPortEntered && (
                          <CountUp
                            className="number"
                            end={25}
                            suffix="M+"
                            duration={3}
                          />
                        )}
                      </span>
                    </Waypoint>
                  </h3>
                  <div className="des">Jobs Available</div>
                </div>
                <div className="shape ani7">
                  <img src={shape1} alt="images" />
                </div>
              </div> */}
              {/*  <div className="tes-box ani5">
                <div className="client-box">
                  <div className="avt">
                    <img src={client1} alt="images" />
                    <div className="badge"> </div>
                  </div>
                  <div className="content">
                    <h6 className="number wrap-counter">
                      <Waypoint onEnter={onVWEnter}>
                        <span>
                          {viewPortEntered && (
                            <CountUp
                              className="number"
                              end={480}
                              suffix="+"
                              duration={3}
                            />
                          )}
                        </span>
                      </Waypoint>
                    </h6>
                    <div className="des">Happpy Candidates</div>
                  </div>
                </div>
              </div> */}
              {/*   <div className="icon1 ani3">
                <img src={icon1} alt="images" />
              </div>
              <div className="icon2 ani4">
                <img src={icon2} alt="images" />
              </div>
              <div className="icon3 ani6">
                <img src={icon  3} alt="images" />
              </div> */}
            </div>
          </div>
          <div className="col-lg-5 col-md-12 wow fadeInRight">
            <div
              className="wd-review-job contentbox1 page-text stc"
              style={{ marginLeft: "-293px" }}
            >
              <h3>About Us</h3>
              <p>
                Abu Dhabi National Insurance Company PJSC (ADNIC) was
                established in the United Arab Emirates (UAE) in 1972. ADNIC is
                a leading multi-line regional insurance provider with
                long-standing expertise in providing insurance and reinsurance
                services to individuals and corporates across the Middle East
                and North Africa (MENA) region. ADNIC is licensed by the Central
                Bank of the UAE and listed on Abu Dhabi Securities Exchange
                (ADX).
              </p>
              <br></br>
              <p>
                Headquartered in Abu Dhabi, ADNIC has a wide network of
                branches, as well as sales and customer service centres, across
                the country. ADNIC offers both commercial and consumer insurance
                products that are customisable and scalable. This flexibility
                enables the company to meet the unique and evolving needs of
                individuals, as well as small, mid-sized and large companies in
                the UAE and wider MENA region. ADNIC’s financial maturity,
                robust balance sheet and operational excellence enable the
                Company to meet its obligations to clients and provide peace of
                mind when they need it most. The Company’s financial ratings by
                Standard and Poor’s has been reaffirmed with a rating of ‘A’ and
                a Stable Outlook in 2022. AM Best too has reaffirmed the
                financial strength rating of A (Excellent) and a long-term
                issuer credit rating of “a” (Excellent) with a stable outlook in
                2022.
              </p>
              <br></br>
              <p>
                ADNIC has partnered with notable organisations across the
                Emirates to continue to enhance its sustainability and core
                business model. Notable examples includes ADNIC’s partnership
                with the Social Investment Fund, administered by the Authority
                of Social Contribution - Ma’an, which started in 2020 to support
                the UAE community during its fight against the pandemic. In
                2023, ADNIC contributed AED 2 million to Ma’an to support
                societal priorities in Abu Dhabi. ADNIC has also collaborated
                with Emirates Nature-WWF for the third consecutive year,
                contributing to local conservation projects to help pave the way
                for the UAE to achieve its net zero goals, as well as the Reach
                campaign, contributing AED 1.5 million to support its efforts to
                combat neglected tropical diseases (NTDs).
              </p>
              <br></br>
              <p>
                ADNIC is also proud to work with the government to encourage UAE
                nationals to work in the private sector, developing a UAE
                national talent programme called “Emkanati”. The programme
                trains Emiratis in ADNIC underwriting divisions as the company
                is working towards enhancing the skills of its workforce to
                ensure they develop essential technical, behavioural and
                leadership skills required in their current and future roles.
              </p>
              <br></br>
              <p>
                Thank you for choosing UAE ADNIC Insurance, your trusted partner
                in healthcare
              </p>
              {/*  <Link to="#" className="tf-button style-1">
                Read more
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CertificateReview;
