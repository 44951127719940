import React, { useState } from "react";
import PropTypes from "prop-types";
import MapBox, { Marker, Popup, NavigationControl } from "react-map-gl";

import "mapbox-gl/dist/mapbox-gl.css";
import { Link } from "react-router-dom";
import "./style.scss";
import { useEffect } from "react";
import { GlobalData } from "../../UserContext/userContext";

MapSingle.propTypes = {};

function MapSingle({ marKers }) {
  const { mapData } = GlobalData();
  //console.log(marKers, "makers");

  const [popupOpen, setPopupOpen] = useState({});
  const [viewPort, setViewPort] = useState({
    latitude: mapData[1],
    longitude: mapData[0],
    zoom: 14,
  });

  //console.log(viewPort, "viewport");
  //console.log(mapData, "mapData");

  const handleMapClick = () => {
    const clickedLongitude = mapData[0];
    const clickedLatitude = mapData[1];

    const googleMapsUrl = `https://www.google.com/maps?q=${clickedLatitude},${clickedLongitude}`;
    window.open(googleMapsUrl, "_blank");
  };
  return (
    <div className="map-content">
      <MapBox
        mapLib={import("mapbox-gl")}
        initialViewState={{
          ...viewPort,
        }}
        mapboxAccessToken="pk.eyJ1IjoidGhlbWVzZmxhdCIsImEiOiJjbGt3NGxtYncwa2F2M21saHM3M21uM3h2In0.9NbzjykXil1nELxQ1V8rkA"
        style={{ width: "100%", height: 300 }}
        mapStyle="mapbox://sprites/mapbox/streets-v8"
        scrollZoom={true}
        onClick={handleMapClick}
      >
        {marKers.map((item) => {
          return (
            <div key={item.id}>
              <Marker
                longitude={item.longitude}
                latitude={item.latitude}
                anchor="center"
                closeOnClick={false}
                onClick={(e) => {
                  setPopupOpen((prevItem) => ({
                    ...prevItem,
                    [item.id]: !prevItem[item.id],
                  }));
                }}
              >
                <div className="marker marker-logo-cty">
                  <img
                    src={`${process.env.REACT_APP_API_URL}${item.img}`}
                    alt="img"
                    style={{
                      width: "35px",
                      height: "35px",
                      borderRadius: "50%",
                    }}
                  />
                </div>
              </Marker>
              {popupOpen[item.id] && (
                <Popup
                  key={item.id}
                  longitude={item.longitude}
                  latitude={item.latitude}
                  anchor="center"
                  onClose={() => setPopupOpen(false)}
                  closeOnClick={false}
                  closeButton={true}
                  offsetLeft={10}
                >
                  <div className="marker-popup">
                    <img src={item.img} alt="img" />
                    <div className="content">
                      <h4>{item.title}</h4>
                      <h3>
                        <Link to="/Jobsingle_v1">
                          {item.name}&nbsp;<span className="icon-bolt"></span>
                        </Link>
                      </h3>
                      <p>
                        <i className="icon-map-pin"></i>&nbsp;
                        {item.address}
                      </p>
                    </div>
                  </div>
                </Popup>
              )}
            </div>
          );
        })}

        <NavigationControl position="top-left" />
      </MapBox>
    </div>
  );
}

export default MapSingle;
