import React, { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import SelectLocation from "../dropdown";
import { sendOTP } from "../../Services/auth.services";
import RegisterModal from "../../pages/Login&Reg_page/Reg_page/RegisterModal";
import Dropdown from "react-dropdown";
import registerManager from "../../assets/images/register-manager.jpg";
import registerUser from "../../assets/images/user-registration-image.jpg";
import img from "../../assets/images/review/fblogo.png";
import img2 from "../../assets/images/review/google.png";
import img3 from "../../assets/images/review/tweet.png";

function SignUp() {
  const [showPass, setShowPass] = useState(false);
  const [show, setShow] = useState(false);
  const [regSuccessMsg, setRegSuccessMsg] = useState(false);
  const [NewRegDetails, setNewRegDetails] = useState([]);
  const [dropDownValue, setDropDownValue] = useState();
  const [image, setImage] = useState(false);
  //console.log("🚀 + SignUp + NewRegDetails:", NewRegDetails);

  /* const [showPass2, setShowPass2] = useState(false);
  const [showPass3, setShowPass3] = useState(false);
  const [showPass4, setShowPass4] = useState(false); */

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm({ mode: "all" });
  const facilityType = watch("facility_type");

  // Update the form value when facility_type changes
  const handleFacilityChange = (e) => {
    setDropDownValue(e.value);
    setValue("facility_type", e.value);
    // setNewRegDetails((prev) => ({
    //   ...prev,
    //   facility_type: e.value,
    // }));
  };

  const formSubmit = (formData) => {
    // console.log("🚀 + formSubmit + formData:", formData);

    setNewRegDetails(formData);
    sendOTP(formData, setShow);
  };

  const options = [
    { value: "Hospital User", label: "Hospital" },
    { value: "Clinic", label: "Clinic" },
    { value: "Medical Lab", label: "Medical Lab" },
    { value: "Company", label: "Company" },
    { value: "Pharmacy", label: "Pharmacy" },
  ];

  // const handleFacilityChange = (e) => {
  //   setNewRegDetails((prev) => ({
  //     ...prev,
  //     facility_type: e.value,
  //   }));
  // };
  return (
    <section className="account-section">
      <div className="register-container">
        <div className="row shadow-lg ">
          <div className="col-md-4 p-3">
            <Tabs className="wd-form-login tf-tab">
              {/*  <h4>Create Link free account</h4> */}
              <TabList className="menu-tab">
                <Tab className="ct-tab" onClick={() => setImage(!image)}>
                  User
                </Tab>
                <Tab className="ct-tab" onClick={() => setImage(!image)}>
                  Facility Manager
                </Tab>
              </TabList>
              <div className="content-tab">
                <TabPanel className="inner animation-tab">
                  <form action="/" onSubmit={handleSubmit(formSubmit)}>
                    <div className="d-flex justify-center m-3">
                      <Link to="#" className="btn-social m-2">
                        <img src={img} alt="images" className="m-1" />
                      </Link>
                      <Link to="#" className="btn-social m-2">
                        <img src={img2} alt="images" className="m-1" />
                      </Link>
                      <Link to="#" className="btn-social m-2">
                        <img src={img3} alt="images" className="m-1" />
                      </Link>
                    </div>
                    <p className="line-ip">
                      <span>or sign up with</span>
                    </p>
                    <div className="ip">
                      <label>
                        Username<span>*</span>
                      </label>
                      {errors.name && (
                        <p className="login-error">This field is required</p>
                      )}
                      <input
                        type="text"
                        id="name"
                        {...register("name", { required: true })}
                        placeholder="Enter your name"
                      />
                    </div>
                    <div className="ip">
                      <label>
                        Email address<span>*</span>
                      </label>
                      {errors.email && (
                        <p className="login-error">{errors.email.message}</p>
                      )}
                      <input
                        type="text"
                        id="email"
                        {...register("email", {
                          required: "Email is required",
                          pattern: {
                            value:
                              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                            message: "Invalid email address format",
                          },
                        })}
                        placeholder="Enter your email address"
                      />
                    </div>
                    <div className="ip">
                      <label>
                        Mobile Number<span>*</span>
                      </label>
                      {errors.number && (
                        <p className="login-error">{errors.number.message}</p>
                      )}
                      <input
                        type="number"
                        id="number"
                        {...register("number", {
                          required: "Mobile number is required",
                          pattern: {
                            value: /^[0-9]{10}$/,
                            message: "Invalid mobile number format",
                          },
                        })}
                        style={{ "-moz-appearance": "textfield" }}
                        pattern="\d*"
                        placeholder="Enter your mobile number"
                      />
                    </div>
                    <div className="ip">
                      <label>
                        Password<span>*</span>
                      </label>
                      {errors.password && (
                        <p className="login-error">
                          {errors.password.type === "required" &&
                            "Password is required."}
                          {errors.password.type === "minLength" &&
                            "Password must be at least 5 characters long."}
                          {errors.password.type === "pattern" &&
                            "Password must contain at least one digit and one special character."}
                        </p>
                      )}
                      <div className="inputs-group auth-pass-inputgroup">
                        <input
                          type={showPass ? "text" : "password"}
                          className="input-form password-input"
                          placeholder="Password"
                          id="password-input"
                          {...register("password", {
                            required: true,
                            minLength: 5,
                            pattern:
                              /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{5,}$/,
                          })}
                        />
                        <Link
                          className={`password-addon ${
                            showPass ? "icon-eye" : "icon-eye-off"
                          }`}
                          onClick={() => setShowPass(!showPass)}
                        />
                      </div>
                    </div>
                    {/*  <div className="ip">
                    <label>
                      Confirm Password<span>*</span>
                    </label>
                    <div className="inputs-group auth-pass-inputgroup">
                      <input
                        type={`${showPass2 ? "text" : "password"}`}
                        className="input-form password-input"
                        defaultValue="userabcdefg123"
                        placeholder="Password"
                        required
                      />
                      <Link
                        className={`password-addon ${
                          showPass2 ? "icon-eye" : "icon-eye-off"
                        }`}
                        onClick={() => setShowPass2(!showPass2)}
                      />
                    </div>
                  </div> */}
                    <div className="group-ant-choice st ip">
                      <div className="sub-ip ">
                        {errors.terms && (
                          <p className="login-error">{errors.terms.message}</p>
                        )}
                        <input
                          type="checkbox"
                          {...register("terms", {
                            required: "This field is required",
                          })}
                        />
                        I agree to the
                        <Link to="/termsofuse"> Terms of User</Link>
                      </div>
                    </div>
                    <button type="submit">Register</button>
                    <div className="sign-up">
                      Already have an account?
                      {/* <Link to="/login">&nbsp;Login Here</Link> */}
                      <Link to="https://app.hospitalsinuae.com/auth-login">
                        &nbsp;Login Here
                      </Link>
                    </div>
                  </form>
                </TabPanel>
                <TabPanel className="inner animation-tab">
                  <form action="/" onSubmit={handleSubmit(formSubmit)}>
                    <div className="ip">
                      <label>
                        Please Select Facility<span>*</span>
                      </label>
                      <div className="group-input register-login-custom">
                        <Dropdown
                          name="facility_type"
                          id="facility_type"
                          options={options}
                          value={dropDownValue}
                          className="react-dropdown select2"
                          onChange={handleFacilityChange}
                          // {...register("facility_type", {
                          //   required: "Facility type is required",
                          // })}
                        />
                        {errors.facility_type && (
                          <p className="login-error">
                            {errors.facility_type.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="ip">
                      <label>
                        Username<span>*</span>
                      </label>
                      {errors.name && (
                        <p className="login-error">This field is required</p>
                      )}
                      <input
                        type="text"
                        id="name"
                        {...register("name", { required: true })}
                        placeholder="Enter your name"
                      />
                    </div>
                    <div className="ip">
                      <label>
                        Email address<span>*</span>
                      </label>
                      {errors.email && (
                        <p className="login-error">{errors.email.message}</p>
                      )}
                      <input
                        type="text"
                        id="email"
                        {...register("email", {
                          required: "Email is required",
                          pattern: {
                            value:
                              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                            message: "Invalid email address format",
                          },
                        })}
                        placeholder="Enter your email address"
                      />
                    </div>
                    <div className="ip">
                      <label>
                        Mobile Number<span>*</span>
                      </label>
                      {errors.number && (
                        <p className="login-error">{errors.number.message}</p>
                      )}

                      <input
                        type="number"
                        id="number"
                        {...register("number", {
                          required: "Mobile number is required",
                          pattern: {
                            value: /^[0-9]{10}$/,
                            message: "Invalid mobile number format",
                          },
                        })}
                        style={{ "-moz-appearance": "textfield" }}
                        pattern="\d*"
                        placeholder="Enter your mobile number"
                      />
                    </div>
                    <div className="ip">
                      <label>
                        Password<span>*</span>
                      </label>
                      {errors.password && (
                        <p className="login-error">
                          {errors.password.type === "required" &&
                            "Password is required."}
                          {errors.password.type === "minLength" &&
                            "Password must be at least 5 characters long."}
                          {errors.password.type === "pattern" &&
                            "Password must contain at least one digit and one special character."}
                        </p>
                      )}
                      <div className="inputs-group auth-pass-inputgroup">
                        <input
                          type={showPass ? "text" : "password"}
                          className="input-form password-input"
                          placeholder="Password"
                          id="password-input"
                          {...register("password", {
                            required: true,
                            minLength: 5,
                            pattern:
                              /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{5,}$/,
                          })}
                        />
                        <Link
                          className={`password-addon ${
                            showPass ? "icon-eye" : "icon-eye-off"
                          }`}
                          onClick={() => setShowPass(!showPass)}
                        />
                      </div>
                    </div>

                    {/*  <div className="ip">
                    <label>
                      Confirm Password<span>*</span>
                    </label>
                    <div className="inputs-group auth-pass-inputgroup">
                      <input
                        type={`${showPass2 ? "text" : "password"}`}
                        className="input-form password-input"
                        defaultValue="userabcdefg123"
                        placeholder="Password"
                        required
                      />
                      <Link
                        className={`password-addon ${
                          showPass2 ? "icon-eye" : "icon-eye-off"
                        }`}
                        onClick={() => setShowPass2(!showPass2)}
                      />
                    </div>
                  </div> */}
                    <div className="group-ant-choice st ip">
                      <div className="sub-ip ">
                        {errors.terms && (
                          <p className="login-error">{errors.terms.message}</p>
                        )}
                        <input
                          type="checkbox"
                          {...register("terms", {
                            required: "This field is required",
                          })}
                        />
                        I agree to the
                        <Link to="/termsofuse"> Terms of User</Link>
                      </div>
                    </div>
                    <button type="submit">Register</button>
                    <div className="sign-up">
                      Already have an account?
                      <Link to="/login">&nbsp;Login Here</Link>
                    </div>
                  </form>
                </TabPanel>
                {/*  <TabPanel className="inner animation-tab">
                <form action="/">
                  <div className="ip">
                    <label>
                      Username or email address<span>*</span>
                    </label>
                    <input
                      type="text"
                      defaultValue="Tony Nguyen"
                      placeholder="Name"
                    />
                  </div>
                  <div className="ip">
                    <label>
                      Password<span>*</span>
                    </label>
                    <div className="inputs-group auth-pass-inputgroup">
                      <input
                        type={`${showPass3 ? "text" : "password"}`}
                        className="input-form password-input"
                        defaultValue="userabcdefg123"
                        placeholder="Password"
                        required
                      />
                      <Link
                        className={`password-addon ${
                          showPass3 ? "icon-eye" : "icon-eye-off"
                        }`}
                        onClick={() => setShowPass3(!showPass3)}
                      />
                    </div>
                  </div>
                  <div className="ip">
                    <label>
                      Confirm Password<span>*</span>
                    </label>
                    <div className="inputs-group auth-pass-inputgroup">
                      <input
                        type={`${showPass4 ? "text" : "password"}`}
                        className="input-form password-input"
                        defaultValue="userabcdefg123"
                        placeholder="Password"
                        required
                      />
                      <Link
                        className={`password-addon ${
                          showPass4 ? "icon-eye" : "icon-eye-off"
                        }`}
                        onClick={() => setShowPass4(!showPass4)}
                      />
                    </div>
                  </div>
                  <div className="group-ant-choice st">
                    <div className="sub-ip">
                      <input type="checkbox" />I agree to the
                      <Link to="/termsofuse"> Terms of User</Link>
                    </div>
                  </div>
                  <button>Register</button>
                  <div className="sign-up">
                    Already have an account?
                    <Link to="/login">&nbsp;Login Here</Link>
                  </div>
                </form>
              </TabPanel> */}
              </div>
            </Tabs>
          </div>
          {/* {image ? (
            <div className="col-md-8 d-none d-md-block">
              <img src={registerManager} alt="register-image" />
            </div>
          ) : (
            <div className="col-md-8 d-none d-md-block">
              <img src={registerUser} alt="register-image" />
            </div>
          )} */}
          <div className="col-md-8 d-none d-md-block p-0">
            <div
              style={{
                backgroundImage: `url(${
                  image ? registerManager : registerUser
                })`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: "100%",
                width: "100%",
              }}
            ></div>
          </div>
        </div>
        <RegisterModal
          setShow={setShow}
          show={show}
          NewRegDetails={NewRegDetails}
          setRegSuccessMsg={setRegSuccessMsg}
          regSuccessMsg={regSuccessMsg}
        />
      </div>
    </section>
  );
}

export default SignUp;
