import React from "react";
import Header2 from "../../components/header/Header2";
import Breadcrumb from "../../components/breadcrumb";
import Gotop from "../../components/gotop";
import Footer from "../Footer_pages/Footer/CustomFooter";
import { useState } from "react";
import dataCandi from "../../assets/fakeData/dataCandi";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import { DepartmentGlobalData } from "../../UserContext/DepartmentContext";

const AllCategory = () => {
  const [toggle, setToggle] = useState({
    key: "",
    status: false,
  });
  const [isShowMobile, setShowMobile] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [loading, setLoading] = useState(true);
  const {
    departmentContext,
    setDepartmentContext,
    setDepartmentValue,
    setTreatment,
  } = DepartmentGlobalData();

  const handleToggle = (key) => {
    if (toggle.key === key) {
      setToggle({
        status: false,
      });
    } else {
      setToggle({
        status: true,
        key,
      });
    }
  };

  const handleMobile = () => {
    const getMobile = document.querySelector(".menu-mobile-popup");
    setShowMobile(!isShowMobile);
    !isShowMobile
      ? getMobile.classList.add("modal-menu--open")
      : getMobile.classList.remove("modal-menu--open");
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/method/hdir.api.get_category`
      );
      setCategoryData(response.data.message);
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    } catch (err) {
      console.log(err, "==this is error");
    } finally {
      // setTimeout(() => {
      //   setLoading(false);
      // }, 2000);
    }
  };

  return (
    <div>
      <Header2 clname="actBlog1" />
      <Breadcrumb title="All Categories" />

      <section className="candidates-section">
        <div className="tf-container">
          <div className="row p-3">
            {categoryData.map((idx) => {
              // const height = 0; // Example calculation: 50px per item

              return (
                <div
                  className="col-md-3"
                  key={idx.name}
                  // style={{ height: `${height}px` }}
                >
                  {loading ? (
                    <>
                      <Skeleton count={1} width={100} height={40} />
                      <Skeleton count={5} />
                    </>
                  ) : (
                    <ul className="list-dot" style={{ fontSize: "15px" }}>
                      <p
                        className="fw-bold pb-1"
                        style={{ borderBottom: "3px solid #123841" }}
                      >
                        <Link
                          to={`/treatment/${idx.name}`}
                          onClick={() => setDepartmentValue(idx.category_name)}
                        >
                          {idx.name}
                        </Link>
                      </p>
                      <span className="divider"></span>
                      <div className="mt-2">
                        {idx.subs.map((sub, i) => (
                          <li key={i} style={{ fontSize: "14px" }}>
                            <Link
                              to={`/hospital-list/All Location`}
                              onClick={() => {
                                setDepartmentValue(idx.category_name);
                                setTreatment(sub.category_name);
                              }}
                            >
                              {sub?.category_name}
                            </Link>
                          </li>
                        ))}
                      </div>
                    </ul>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <Footer />
      <Gotop />
    </div>
  );
};

export default AllCategory;
