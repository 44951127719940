import React from "react";
import Skeleton from "react-loading-skeleton";
const CategorySkeleton = () => {
  return (
    <div>
      <div className="text-center py-3">
        <div className="employer-block style-2 cl2">
          <div className="inner-box justify-content-center">
            <h6>
              <Skeleton width={310} height={30} />
            </h6>
          </div>
          <div>
            <ul className="hospital-listing-box">
              <li>
                <Skeleton width={200} height={20} />
              </li>
              <li>
                <Skeleton width={150} height={20} />
              </li>
              <li>
                <Skeleton width={180} height={20} />
              </li>
              <li>
                <Skeleton width={120} height={20} />
              </li>
              <li>
                <Skeleton width={160} height={20} />
              </li>
              <li>
                <Skeleton width={200} height={20} />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategorySkeleton;

export const CategoryDes = () => {
  return (
    <>
      <ul className="d-flex  ">
        <li>
          <Skeleton width={300} height={20} />
        </li>
        <li>
          <Skeleton width={300} height={20} />
        </li>
        <li>
          <Skeleton width={300} height={20} />
        </li>
      </ul>
      <p>
        <Skeleton width={300} height={20} />
      </p>
    </>
  );
};
