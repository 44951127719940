import React from "react";
import PropTypes from "prop-types";
import Breadcrumb from "../../components/breadcrumb";
import JobSec3 from "../../components/jobs/JobSec3";
import dataJobs from "../../assets/fakeData/dataJobs";
import Footer from "../../components/footer";
import Gotop from "../../components/gotop";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Collapse } from "react-collapse";
import logo from "../../assets/images/LogoBgRemoved.png";
import Header4 from "../../components/header/Header4";
import Header2 from "../../components/header/Header2";
import noImage from "../../assets/images/no-img.jpeg";
import { useParams } from "react-router-dom";
import HospitalDataAxios from "../../components/jobs/HospitalDataAxios";

import { useEffect } from "react";
import CustomFooter from "../../pages/Footer_pages/Footer/CustomFooter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXTwitter } from "@fortawesome/free-brands-svg-icons";
import DoctorsListingData from "./DoctorsListingData";

const DoctorListing = () => {
  const { category } = useParams();
  setTimeout(() => {
    window.scroll({
      top: 1,
      left: 0,
      behavior: "smooth",
    });
  }, 100);

  const [toggle, setToggle] = useState({
    key: "",
    status: false,
  });
  const [isShowMobile, setShowMobile] = useState(false);

  const handleToggle = (key) => {
    if (toggle.key === key) {
      setToggle({
        status: false,
      });
    } else {
      setToggle({
        status: true,
        key,
      });
    }
  };

  const handleMobile = () => {
    const getMobile = document.querySelector(".menu-mobile-popup");
    setShowMobile(!isShowMobile);
    !isShowMobile
      ? getMobile.classList.add("modal-menu--open")
      : getMobile.classList.remove("modal-menu--open");
  };
  return (
    <div>
      {" "}
      <div className="menu-mobile-popup">
        <div className="modal-menu__backdrop" onClick={handleMobile}></div>
        <div className="widget-filter">
          <div className="mobile-header">
            <div id="logo" className="logo">
              <Link to="/">
                <img className="site-logo" src={logo} alt="Image" />
              </Link>
            </div>
            <Link className="title-button-group" onClick={handleMobile}>
              <i className="icon-close"></i>
            </Link>
          </div>

          <Tabs className="tf-tab">
            <TabList className="menu-tab">
              <Tab className="user-tag">Menu</Tab>
              {/* <Tab className="user-tag">Categories</Tab> */}
            </TabList>

            <div className="content-tab">
              <TabPanel className="header-ct-center menu-moblie animation-tab">
                <div className="nav-wrap">
                  <nav className="main-nav mobile">
                    <ul id="menu-primary-menu" className="menu">
                      {/*   <li className="menu-item menu-item-has-children-mobile">
                  <Link
                    to="#"
                    className="iteam-menu"
                    onClick={() => {
                      handleToggle("home");
                    }}
                  >
                    Home
                  </Link>
                  <Collapse isOpened={toggle.key === "home"}>
                    <ul
                      className="sub-menu-mobile"
                      style={{
                        display: `${
                          toggle.key === "home" ? "block" : "none"
                        }`,
                      }}
                    >
                      <li className="menu-item menu-item-mobile">
                        <Link to="/">Home Page 01 </Link>
                      </li>
                      <li className="menu-item menu-item-mobile">
                        <Link to="/home_v2">Home Page 02 </Link>
                      </li>
                      <li className="menu-item menu-item-mobile">
                        <Link to="/home_v3">Home Page 03 </Link>
                      </li>
                      <li className="menu-item menu-item-mobile">
                        <Link to="/home_v4">Home Page 04 </Link>
                      </li>
                      <li className="menu-item menu-item-mobile">
                        <Link to="/home_v5">Home Page 05 </Link>
                      </li>
                      <li className="menu-item menu-item-mobile">
                        <Link to="/home_v6">Home Page 06 </Link>
                      </li>
                      <li className="menu-item menu-item-mobile">
                        <Link to="/home_v7">Home Page 07 </Link>
                      </li>
                      <li className="menu-item menu-item-mobile">
                        <Link to="/home_v8">Home Page 08 </Link>
                      </li>
                      <li className="menu-item menu-item-mobile">
                        <Link to="/home_v9">Home Page 09 </Link>
                      </li>
                      <li className="menu-item menu-item-mobile">
                        <Link to="/home_v10">Home Page 10 </Link>
                      </li>
                    </ul>
                  </Collapse>
                </li>

                <li className="menu-item menu-item-has-children-mobile current-item">
                  <Link
                    to="#"
                    className="iteam-menu"
                    onClick={() => {
                      handleToggle("job");
                    }}
                  >
                    Find jobs
                  </Link>
                  <Collapse isOpened={toggle.key === "job"}>
                    <ul
                      className="sub-menu-mobile"
                      style={{
                        display: `${
                          toggle.key === "job" ? "block" : "none"
                        }`,
                      }}
                    >
                      <li className="menu-item menu-item-mobile">
                        <Link to="/joblist_v1">List Layout</Link>
                      </li>
                      <li className="menu-item menu-item-mobile">
                        <Link to="/job-grid">Grid Layout</Link>
                      </li>
                      <li className="menu-item menu-item-mobile current-item">
                        <Link to="/job-list-sidebar">List Sidebar</Link>
                      </li>
                      <li className="menu-item menu-item-mobile">
                        <Link to="/job-grid-sidebar">Grid Sidebar</Link>
                      </li>
                      <li className="menu-item menu-item-mobile">
                        <Link to="/joblist_v5">
                          List Sidebar Fullwidth
                        </Link>
                      </li>
                      <li className="menu-item menu-item-mobile">
                        <Link to="/joblist_v6">
                          Grid Sidebar Fullwidth
                        </Link>
                      </li>
                      <li className="menu-item menu-item-mobile">
                        <Link to="/joblist_v7">Top Map</Link>
                      </li>
                      <li className="menu-item menu-item-mobile">
                        <Link to="/joblist_v8">Top Map Sidebar</Link>
                      </li>
                      <li className="menu-item menu-item-mobile">
                        <Link to="/joblist_v9">Half Map - V1</Link>
                      </li>
                      <li className="menu-item menu-item-mobile">
                        <Link to="/joblist_v10">Half Map - V2</Link>
                      </li>
                      <li className="menu-item menu-item-mobile">
                        <Link to="/jobsingle_v1">Jobs Single - V1</Link>
                      </li>
                      <li className="menu-item menu-item-mobile">
                        <Link to="/jobsingle_v2">Jobs Single - V2</Link>
                      </li>
                    </ul>
                  </Collapse>
                </li>

                <li className="menu-item menu-item-has-children-mobile">
                  <Link
                    to="#"
                    className="iteam-menu"
                    onClick={() => {
                      handleToggle("employers");
                    }}
                  >
                    Employers
                  </Link>
                  <Collapse isOpened={toggle.key === "employers"}>
                    <ul
                      className="sub-menu-mobile"
                      style={{
                        display: `${
                          toggle.key === "employers" ? "block" : "none"
                        }`,
                      }}
                    >
                      <li className="menu-item">
                        <Link to="/employers_v1">List Layout</Link>
                      </li>
                      <li className="menu-item">
                        <Link to="/employers_v2">Grid Layout</Link>
                      </li>
                      <li className="menu-item">
                        <Link to="/employers_v3">List Sidebar</Link>
                      </li>
                      <li className="menu-item">
                        <Link to="/employers_v4">Grid Sidebar</Link>
                      </li>
                      <li className="menu-item">
                        <Link to="/employers_v5">Full Width</Link>
                      </li>
                      <li className="menu-item">
                        <Link to="/employers_v6">Top Map</Link>
                      </li>
                      <li className="menu-item">
                        <Link to="/employers_v7">Half Map</Link>
                      </li>
                      <li className="menu-item">
                        <Link to="/employersingle_v1">
                          Employers Single - V1
                        </Link>
                      </li>
                      <li className="menu-item">
                        <Link to="/employersingle_v2">
                          Employers Single - V2
                        </Link>
                      </li>

                      <li className="menu-item">
                        <Link to="/employerreview">
                          Employers Reviews
                        </Link>
                      </li>
                      <li className="menu-item">
                        <Link to="/employernotfound">
                          Employers Not Found
                        </Link>
                      </li>
                    </ul>
                  </Collapse>
                </li>
                <li className="menu-item menu-item-has-children-mobile">
                  <Link
                    to="#"
                    className="iteam-menu"
                    onClick={() => {
                      handleToggle("candidate");
                    }}
                  >
                    Candidates
                  </Link>
                  <Collapse isOpened={toggle.key === "candidate"}>
                    <ul
                      className="sub-menu-mobile"
                      style={{
                        display: `${
                          toggle.key === "candidate" ? "block" : "none"
                        }`,
                      }}
                    >
                      <li className="menu-item menu-item-mobile">
                        <Link to="/candidates_v1">List Layout</Link>
                      </li>
                      <li className="menu-item menu-item-mobile">
                        <Link to="/candidates_v2">Grid Layout</Link>
                      </li>
                      <li className="menu-item menu-item-mobile">
                        <Link to="/candidates_v3">List Sidebar</Link>
                      </li>
                      <li className="menu-item menu-item-mobile">
                        <Link to="/candidates_v4">Top Map</Link>
                      </li>
                      <li className="menu-item menu-item-mobile">
                        <Link to="/candidates_v5">Half Map</Link>
                      </li>
                      <li className="menu-item menu-item-mobile">
                        <Link to="/candidates_v6">No Available V1</Link>
                      </li>
                      <li className="menu-item menu-item-mobile">
                        <Link to="/candidates_v7">No Available V2</Link>
                      </li>
                      <li className="menu-item menu-item-mobile">
                        <Link to="/candidatesingle_v1">
                          Candidate Single - V1
                        </Link>
                      </li>
                      <li className="menu-item menu-item-mobile">
                        <Link to="/candidatesingle_v2">
                          Candidate Single - V2
                        </Link>
                      </li>
                      <li className="menu-item menu-item-mobile">
                        <Link to="/samplecv">Sample CV</Link>
                      </li>
                      <li className="menu-item menu-item-mobile">
                        <Link to="/samplecvslidebar">
                          Sample CV Sidebar
                        </Link>
                      </li>
                      <li className="menu-item menu-item-mobile">
                        <Link to="/samplecvdetails">CV Details</Link>
                      </li>
                    </ul>
                  </Collapse>
                </li>
                <li className="menu-item menu-item-has-children-mobile">
                  <Link
                    to="#"
                    className="iteam-menu"
                    onClick={() => {
                      handleToggle("blog");
                    }}
                  >
                    Blog
                  </Link>
                  <Collapse isOpened={toggle.key === "blog"}>
                    <ul
                      className="sub-menu-mobile"
                      style={{
                        display: `${
                          toggle.key === "blog" ? "block" : "none"
                        }`,
                      }}
                    >
                      <li className="menu-item menu-item-mobile">
                        <Link to="/blog_v1">Blog List </Link>
                      </li>
                      <li className="menu-item menu-item-mobile">
                        <Link to="/blog_v2">Blog Grid</Link>
                      </li>
                      <li className="menu-item menu-item-mobile">
                        <Link to="/blog_v3">Blog Masonry</Link>
                      </li>
                      <li className="menu-item menu-item-mobile">
                        <Link to="/blogsingle_v1">Blog Details - V1</Link>
                      </li>
                      <li className="menu-item menu-item-mobile">
                        <Link to="/blogsingle_v2">Blog Details - V2</Link>
                      </li>
                      <li className="menu-item menu-item-mobile">
                        <Link to="/blogsingle_v3">
                          Blog Details Sidebar
                        </Link>
                      </li>
                    </ul>
                  </Collapse>
                </li>
                <li className="menu-item menu-item-has-children-mobile">
                  <Link
                    to="#"
                    className="iteam-menu"
                    onClick={() => {
                      handleToggle("pages");
                    }}
                  >
                    Pages
                  </Link>
                  <Collapse isOpened={toggle.key === "pages"}>
                    <ul
                      className="sub-menu-mobile"
                      style={{
                        display: `${
                          toggle.key === "pages" ? "block" : "none"
                        }`,
                      }}
                    >
                      <li className="menu-item menu-item-mobile">
                        <Link to="/aboutus">About Us</Link>
                      </li>
                      <li className="menu-item menu-item-mobile">
                        <Link to="/faqs">FAQS</Link>
                      </li>
                      <li className="menu-item menu-item-mobile">
                        <Link to="/termsofuse">Terms Of Use</Link>
                      </li>
                      <li className="menu-item menu-item-mobile">
                        <Link to="/pricing">Pricing</Link>
                      </li>
                      <li className="menu-item menu-item-mobile">
                        <Link to="/shop">Shop List</Link>
                      </li>
                      <li className="menu-item menu-item-mobile">
                        <Link to="/shoppingcart">Shopping Cart</Link>
                      </li>
                      <li className="menu-item menu-item-mobile">
                        <Link to="/shopsingle">Shop Single</Link>
                      </li>
                      <li className="menu-item menu-item-mobile">
                        <Link to="/checkout">Checkout</Link>
                      </li>
                      <li className="menu-item menu-item-mobile">
                        <Link to="/login">Login</Link>
                      </li>
                      <li className="menu-item menu-item-mobile">
                        <Link to="/createaccount">Create Account</Link>
                      </li>
                      <li className="menu-item menu-item-mobile">
                        <Link to="/contactus">Contact Us</Link>
                      </li>
                    </ul>
                  </Collapse>
                </li> */}
                      <li className="menu-item menu-item-has-children-mobile">
                        <Link
                          to="https://hadmin.prismaticsoft.com/auth-login"
                          target="_blank"
                        >
                          Members Login
                        </Link>
                      </li>
                      <li className="menu-item menu-item-has-children-mobile">
                        <Link
                          to="https://hadmin.prismaticsoft.com/auth-login"
                          target="_blank"
                        >
                          New Registration
                        </Link>
                      </li>
                      {/* <li className="menu-item menu-item-has-children-mobile">
                  <Link
                    to="https://hjobs.prismaticsoft.com/"
                    target="_blank"
                  >
                    Jobs
                  </Link>
                </li>

                <li className="menu-item menu-item-has-children-mobile">
                  <Link
                    to="/pricing"
                    className=""
                    /*  onClick={() => {
                      handleToggle("pages");
                    }} 
                  >
                    Pricing
                  </Link>
                </li> */}
                    </ul>
                  </nav>
                </div>
              </TabPanel>

              <TabPanel className="categories animation-tab">
                <div className="sub-categorie-mobile">
                  <ul className="pop-up">
                    <li className="categories-mobile">
                      <Link to="/jobsingle_v1">
                        <span className="icon-categorie-1"></span>Design &
                        Creative
                      </Link>
                    </li>
                    <li className="categories-mobile">
                      <Link to="/jobsingle_v1">
                        <span className="icon-categorie-8"></span>Digital
                        Marketing
                      </Link>
                    </li>
                    <li className="categories-mobile">
                      <Link to="/jobsingle_v1">
                        <span className="icon-categorie-2"></span>Development &
                        IT
                      </Link>
                    </li>
                    <li className="categories-mobile">
                      <Link to="/jobsingle_v1">
                        <span className="icon-categorie-3"></span>Music & Audio
                      </Link>
                    </li>
                    <li className="categories-mobile">
                      <Link to="/jobsingle_v1">
                        <span className="icon-categorie-4"></span>Finance &
                        Accounting
                      </Link>
                    </li>
                    <li className="categories-mobile">
                      <Link to="/jobsingle_v1">
                        <span className="icon-categorie-5"></span>Programming &
                        Tech
                      </Link>
                    </li>
                    <li className="categories-mobile">
                      <Link to="/jobsingle_v1">
                        <span className="icon-categorie-6"></span>Video &
                        Animation
                      </Link>
                    </li>
                    <li className="categories-mobile">
                      <Link to="/jobsingle_v1">
                        <span className="icon-categorie-7"></span>Writing &
                        translation
                      </Link>
                    </li>
                  </ul>
                </div>
              </TabPanel>
            </div>
          </Tabs>

          {/*  <div className="header-customize-item button">
      <Link to="/">Upload Resume</Link>
    </div> */}

          <div className="mobile-footer">
            <div className="icon-infor d-flex aln-center">
              <div className="icon">
                {/* <span className="icon-call-calling">
            <span className="path1"></span>
            <span className="path2"></span>
            <span className="path3"></span>
            <span className="path4"></span>
          </span> */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={35}
                  height={20}
                  viewBox="0 0 52 40"
                  fill="none"
                >
                  <path
                    d="M47.125 0.5H4.87503C2.18677 0.5 0 2.68678 0 5.37504V34.625C0 37.3133 2.18677 39.5001 4.87503 39.5001H47.125C49.8132 39.5001 52 37.3133 52 34.625V5.37504C52 2.68678 49.8132 0.5 47.125 0.5ZM47.125 3.74999C47.3457 3.74999 47.5558 3.7958 47.7478 3.87579L26 22.7248L4.25209 3.87579C4.4441 3.7959 4.65414 3.74999 4.87493 3.74999H47.125ZM47.125 36.25H4.87503C3.97844 36.25 3.24999 35.5216 3.24999 34.6249V7.30944L24.9352 26.1033C25.2415 26.3682 25.6207 26.5 26 26.5C26.3793 26.5 26.7585 26.3684 27.0648 26.1033L48.75 7.30944V34.625C48.7499 35.5216 48.0216 36.25 47.125 36.25Z"
                    fill="green"
                  />
                </svg>
              </div>
              <div className="content">
                <p>Need help? 24/7</p>
                <h6>
                  <a href="mailto:support@hospitalsinuae.com">
                    support@hospitalsinuae.com
                  </a>
                </h6>
              </div>
            </div>
            <div className="wd-social d-flex aln-center">
              <ul className="list-social d-flex aln-center">
                {/* <li>
            <Link to="#">
              <i className="icon-facebook"></i>
            </Link>
          </li>
          <li>
            <Link to="#">
              <i className="icon-linkedin2"></i>
            </Link>
          </li>
          <li>
            <Link to="#">
              <i className="icon-twitter"></i>
            </Link>
          </li>
          <li>
            <Link to="#">
              <i className="icon-pinterest"></i>
            </Link>
          </li>
          <li>
            <Link to="#">
              <i className="icon-instagram1"></i>
            </Link>
          </li>
          <li>
            <Link to="#">
              <i className="icon-youtube"></i>
            </Link>
          </li> */}
                <li>
                  <Link to="#">
                    <i className="icon-facebook"></i>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="icon-linkedin2"></i>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    {/*  <i className="icon-twitter"></i> */}
                    <FontAwesomeIcon icon={faXTwitter} />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* <Header4 clname="actJob1" handleMobile={handleMobile} /> */}
      <Header2 clname="actBlog1" handleMobile={handleMobile} />
      <Breadcrumb title="Find Doctors" className="breadcrumb-section" />
      {/* <JobSec3 data={dataJobs} /> */}
      <DoctorsListingData />
      {/*    <Footer /> */}
      <CustomFooter />
      <Gotop />
    </div>
  );
};

export default DoctorListing;
