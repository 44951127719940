import React from "react";

import Skeleton from "react-loading-skeleton";

function HospitalListingSkeletons() {
  return (
    <div className="">
      <div className="job-archive-header">
        <div>
          <div className="row ">
            <div className="col-lg-5 hospital-data-container">
              <div className="hospital-data">
                <Skeleton width={310} height={205} />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="box-content">
                <Skeleton width={150} />
                <div className="hospital-data-logo">
                  {/* <div>
                    <Skeleton count={3} />
                  </div> */}

                  {/* <div>
                    <Skeleton />
                  </div> */}
                </div>
                {/*   <span className="icon-heart"></span> */}
                <div className="hospital-data-about pt-1">
                  <Skeleton count={6} />
                </div>
              </div>
              <div
                style={{
                  textAlign: "right",
                }}
              >
                <Skeleton width={150} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HospitalListingSkeletons;
