import React from "react";
import PropTypes from "prop-types";
import RangeOne from "../range-slider";
import RangeTwo from "../range-slider/RangleTwo";
import SelectLocation from "../dropdown";
import Dropdown from "react-dropdown";
import { FacilityGlobalData } from "../../UserContext/facilityContext";
import { DepartmentGlobalData } from "../../UserContext/DepartmentContext";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { FaRegHospital } from "react-icons/fa";
import { MdOutlineLocalHospital } from "react-icons/md";
import { FaHospitalUser } from "react-icons/fa6";

const select1 = [
  { value: "All Facilities", label: "All Facilities" },
  { value: "Hospital", label: "Hospital" },
  { value: "Clinic", label: "Clinic" },
  { value: "Medical Lab", label: "Medical Lab" },
  { value: "Company", label: "Company" },
  { value: "Pharmacy", label: "Pharmacy" },
];
const select2 = [
  { value: "s1", label: "On-site" },
  { value: "s2", label: "Remote" },
  { value: "s3  ", label: "Freelancer" },
];
const select3 = [
  { value: "s1", label: "All Job Types" },
  { value: "s2", label: "SoftWere" },
  { value: "s3  ", label: "Website" },
];
const select4 = [
  { value: "s1", label: "Posted Anytime " },
  { value: "s3  ", label: "Website" },
];
const select5 = [
  { value: "s1", label: "All Seniority Levels " },
  { value: "s2", label: "Website" },
];
const select6 = [
  { value: "s1", label: "Company" },
  { value: "s2", label: "Website" },
];

Sidebar.propTypes = {};

const options = [
  { value: "All Location", label: "All Location" },
  { value: "Dubai", label: "Dubai" },
  { value: "Ajman", label: "Ajman" },
  { value: "Abu Dhabi", label: "Abu Dhabi" },
  { value: "Sharjah", label: "Sharjah" },
  { value: "Umm Al Quwain", label: "Umm Al Quwain" },
  { value: "Ras Al Khaimah", label: "Ras Al Khaimah" },
  { value: "Fujairah", label: "Fujairah" },
];

function Sidebar({
  handleFacilityChange,
  facility,
  handlechange,
  handleLocationChange,
  dropDownLocation,
  treatmentDetails,
  setDropDowncategory,
  dropDowncategory,
  allCategory,
  setDropDownLocation,
  setAllCategory,
}) {
  const {
    departmentContext,
    setDepartmentContext,
    departmentValue,
    setDepartmentValue,
    treatment,
    setTreatment,
  } = DepartmentGlobalData();
  console.log("🚀 + treatment:", treatment);
  const { setFacility } = FacilityGlobalData();
  const [isSticky, setIsSticky] = useState(false);
  const stickyRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const stickyElement = stickyRef.current;
      const scrollPosition = window.scrollY;
      const halfPageHeight = window.innerHeight / 1;

      if (scrollPosition >= halfPageHeight && !isSticky) {
        setIsSticky(true);
      } else if (scrollPosition < halfPageHeight && isSticky) {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isSticky]);

  const categoryDetails = treatmentDetails?.map((data) => ({
    value: data.name,
    label: data.name,
  }));
  const departmentDetails = departmentContext?.map((data) => ({
    value: data.name,
    label: data.name,
  }));

  //  console.log("🚀 + departmentDetails + departmentDetails:", departmentDetails);
  //console.log("🚀 + categoryDetails + categoryDetails:", categoryDetails);
  const handleReset = () => {
    setDepartmentValue("Select Department");
    setDropDownLocation("All Location");
    setTreatment("Select Treatment");
    setAllCategory((prev) => ({
      ...prev,
      subDescripion: null,
      descripion: null,
    }));
    setFacility("All Facilities");
  };

  return (
    <div
      className={`widget-filter st2  style-scroll ${isSticky && "po-sticky"} `}
      ref={stickyRef}
    >
      <form>
        {/* <div className="group-form">
          <label className="title">Search Hospital</label>
          <div className="group-input search-ip">
            <button>
              <i className="icon-search"></i>
            </button>
            <input
              type="text"
              placeholder="Search Hospitals, Clinics, Pharmacy, Company"
              required
              onChange={(e) => {
                handlechange(e);
              }}
            />
          </div>
        </div> */}
        {/* {!treatmentDetails.length >= 1 ? ( */}
        {/* <div className="group-form">
          <label className="title">Search Hospital</label>
          <div className="group-input search-ip">
            <button>
              <i className="icon-search"></i>
            </button>
            <input
              type="text"
              placeholder="Search Hospitals, Clinics, Pharmacy, Company"
              required
              onChange={(e) => {
                handlechange(e);
              }}
            />
          </div>
        </div> */}
        {/* ) : ( */}
        <div className="pb-3">
          <div className="group-form">
            <label className="title">Departments</label>
            <div className="group-input">
              <i>
                <FaHospitalUser />
              </i>
              <SelectLocation
                value={departmentValue}
                options={departmentDetails}
                onChange={(e) => setDepartmentValue(e.value)}
              />
            </div>
          </div>
          {allCategory?.descripion && (
            <div
              className=" p-1 "
              style={{
                fontSize: "15px",
                textAlign: "justify",
                fontWeight: "600",
              }}
              dangerouslySetInnerHTML={{
                __html: allCategory?.descripion?.description,
              }}
            />
          )}
        </div>
        {/* )} */}
        {treatmentDetails.length >= 1 && (
          <div className="pb-3">
            <div className="group-form">
              <label className="title">Treatment</label>
              <div className="group-input">
                <i>
                  <MdOutlineLocalHospital />
                </i>
                <SelectLocation
                  value={treatment}
                  options={categoryDetails}
                  onChange={(e) => setTreatment(e.value)}
                />
              </div>
            </div>
            {allCategory?.subDescripion && (
              <div
                className=" p-1 "
                style={{
                  fontSize: "15px",
                  textAlign: "justify",
                  fontWeight: "600",
                }}
                dangerouslySetInnerHTML={{
                  __html: allCategory?.subDescripion?.description,
                }}
              />
            )}
          </div>
        )}

        <div className="group-form">
          <label className="title">Facility Type</label>
          <div className="group-input">
            <i>
              <FaRegHospital />
            </i>
            <SelectLocation
              value={facility}
              options={select1}
              onChange={(e) => handleFacilityChange(e)}
            />
          </div>
        </div>
        <div className="group-form">
          <label className="title">Location</label>
          <div className="group-input has-icon">
            <i className="icon-map-pin"></i>
            <SelectLocation
              value={dropDownLocation}
              options={options}
              // onChange={(e) => setDropDownLocation(e.value)}
              onChange={(e) => handleLocationChange(e.value)}
            />
          </div>
        </div>

        {/*   <div className="group-form">
          <label className="title">On-site/Remote</label>
          <div className="group-input">
            <Dropdown
              options={select2}
              className="react-dropdown select2"
              value={select2[0]}
            />
          </div>
        </div> */}
        {/* <div className="group-form">
          <label className="title">Job Types</label>
          <div className="group-input">
            <Dropdown
              options={select3}
              className="react-dropdown select2"
              value={select3[0]}
            />
          </div>
        </div> */}
        {/* <RangeTwo title="Salary:" />
        <RangeOne miles="Miles" title="Radius:" /> */}

        {/* <div className="group-form">
          <label className="title">Posted Anytime</label>
          <div className="group-input">
            <Dropdown
              options={select4}
              className="react-dropdown select2"
              value={select4[0]}
            />
          </div>
        </div> */}
        {/* <div className="group-form">
          <label className="title">All Seniority Levels</label>
          <div className="group-input">
            <Dropdown
              options={select5}
              className="react-dropdown select2"
              value={select5[0]}
            />
          </div>
        </div> */}
        {/* <div className="group-form">
          <label className="title">Company</label>
          <div className="group-input">
            <Dropdown
              options={select6}
              className="react-dropdown select2"
              value={select6[0]}
            />
          </div>
        </div> */}
        <div className="d-flex justify-content-center">
          <span
            type="button"
            className="btn btn-secondary btn-custom-filter"
            onClick={() => handleReset()}
          >
            Reset Filters
          </span>
        </div>
      </form>
    </div>
  );
}

export default Sidebar;
