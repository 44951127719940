import React, { useState } from "react";
import PropTypes from "prop-types";
import CountUp from "react-countup";
import { Waypoint } from "react-waypoint";
import { Link } from "react-router-dom";
import img1 from "../../assets/images/review/thumb2.png";
import shape1 from "../../assets/images/review/shape.png";
import client1 from "../../assets/images/review/client.jpg";
import icon1 from "../../assets/images/review/icon1.png";
import icon2 from "../../assets/images/review/icon2.png";
import icon3 from "../../assets/images/review/icon3.png";
import about from "../../assets/images/about.jpeg";

function Review(props) {
  const [viewPortEntered, setViewPortEntered] = useState(false);

  const onVWEnter = () => {
    setViewPortEntered(true);
  };

  return (
    <section className="inner-review-section">
      <div className="tf-container">
        <div className="row">
          <div className="col-lg-7 col-md-12">
            <div className="wd-review-job thumb2 widget-counter stc">
              <div className="thumb">
                <img src={about} alt="images" />
              </div>
              {/* <div className="trader-box">
                <div className="content">
                  <h3 className="number wrap-counter">
                    <Waypoint onEnter={onVWEnter}>
                      <span>
                        {viewPortEntered && (
                          <CountUp
                            className="number"
                            end={25}
                            suffix="M+"
                            duration={3}
                          />
                        )}
                      </span>
                    </Waypoint>
                  </h3>
                  <div className="des">Jobs Available</div>
                </div>
                <div className="shape ani7">
                  <img src={shape1} alt="images" />
                </div>
              </div> */}
              {/*  <div className="tes-box ani5">
                <div className="client-box">
                  <div className="avt">
                    <img src={client1} alt="images" />
                    <div className="badge"> </div>
                  </div>
                  <div className="content">
                    <h6 className="number wrap-counter">
                      <Waypoint onEnter={onVWEnter}>
                        <span>
                          {viewPortEntered && (
                            <CountUp
                              className="number"
                              end={480}
                              suffix="+"
                              duration={3}
                            />
                          )}
                        </span>
                      </Waypoint>
                    </h6>
                    <div className="des">Happpy Candidates</div>
                  </div>
                </div>
              </div> */}
              <div className="icon1 ani3">
                <img src={icon1} alt="images" />
              </div>
              <div className="icon2 ani4">
                <img src={icon2} alt="images" />
              </div>
              <div className="icon3 ani6">
                <img src={icon3} alt="images" />
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-12 wow fadeInRight">
            <div className="wd-review-job contentbox1 page-text stc ">
              <h3>About Us</h3>
              <p className="pb-3">
                Welcome to Hospitals I N, your premier online platform
                connecting users to caregivers across the Middle East and
                Northern Africa (MENA) region. Our platform is your gateway to
                accessing top-quality healthcare services, tailored to your
                needs, and conveniently located throughout MENA.
              </p>

              <p className="pb-3">
                Hospitals I N simplifies the process of finding caregivers
                across the MENA region. Whether you're seeking specialists,
                primary care physicians, or other healthcare professionals, our
                platform allows you to search based on criteria such as
                specialty, location, and user ratings, ensuring you find the
                perfect match for your needs.
              </p>

              <p className="pb-3">
                Navigating to your chosen caregiver is effortless with Hospitals
                I N. Our platform provides detailed location information and
                directions, ensuring you reach your destination with ease, no
                matter where you are in the MENA region.
              </p>

              <p className="pb-3">
                At Hospitals I N, we're dedicated to enhancing your healthcare
                experience across the MENA region. Our platform continually
                evolves to meet your needs, incorporating user feedback and
                innovative features to ensure your satisfaction.
              </p>

              <p className="pb-3">
                Thank you for choosing Hospitals I N as your trusted partner in
                accessing top-quality healthcare services across the Middle East
                and Northern Africa. We're here to support you on your
                healthcare journey, wherever it may lead.
              </p>
              {/*  <Link to="#" className="tf-button style-1">
                Read more
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Review;
