import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

function CookieTerm() {
  return (
    <section className="term-section">
      <div className="tf-container">
        <Tabs className="row tf-tab">
          <div className="col-lg-4">
            <TabList className="menu-tab tab-term po-sticky">
              <Tab className="ct-tab">1. Acceptance of Terms </Tab>
              <Tab className="ct-tab">2. Use of Services </Tab>
              <Tab className="ct-tab">3. Privacy Policy</Tab>
              <Tab className="ct-tab">4. Medical Information Disclaimer</Tab>
              <Tab className="ct-tab">5. Intellectual Property</Tab>
            </TabList>
          </div>
          <div className="col-lg-8">
            <div className="content-tab">
              <TabPanel className="term-content animation-tab">
                <h4>Introduction</h4>
                {/*  <h6>1. Acceptance of Terms </h6> */}
                <p>
                  Welcome to website. This website uses cookies. By using our
                  website, you consent to the use of cookies in accordance with
                  this Cookies Policy.
                </p>
                {/* <p>
                  In malesuada neque quis libero laoreet posuere. In consequat
                  vitae ligula quis rutrum. Morbi dolor orci, maximus a pulvinar
                  sed, bibendum ac lacus. Suspendisse in consectetur lorem.
                  Pellentesque habitant morbi tristique senectus et netus et
                  malesuada fames ac turpis egestas. Aliquam elementum, est sed
                  interdum cursus, felis ex pharetra nisi, ut elementum tortor
                  urna eu nulla. Donec rhoncus in purus quis blandit.
                </p>
                <p className="mgb-32">
                  Etiam eleifend metus at nunc ultricies facilisis. Morbi
                  finibus tristique interdum. Nullam vel eleifend est, eu
                  posuere risus. Vestibulum ligula ex, ullamcorper sit amet
                  molestie
                </p> */}
                <h6>How We Use Cookies</h6>

                <h6>Essential Cookies</h6>
                <p>
                  These cookies are necessary for the website to function
                  properly. They enable you to navigate our site and use its
                  features.
                </p>
                <h6>Analytical/Performance Cookies</h6>
                <p>
                  These cookies allow us to recognize and count the number of
                  visitors and to see how visitors move around our website when
                  they are using it. This helps us to improve the way our
                  website works, for example, by ensuring that users find what
                  they are looking for easily.
                </p>
                <h6>Functionality Cookies</h6>
                <p>
                  These cookies are used to recognize you when you return to our
                  website. This enables us to personalize our content for you
                  and remember your preferences.
                </p>
                <h6>Targeting Cookies </h6>
                <p>
                  These cookies record your visit to our website, the pages you
                  have visited, and the links you have followed. We will use
                  this information to make our website more relevant to your
                  interests.
                </p>

                <h6>Managing Cookies </h6>
                <p>
                  You can control and/or delete cookies as you wish. You can
                  delete all cookies that are already on your computer and you
                  can set most browsers to prevent them from being placed. If
                  you do this, however, you may have to manually adjust some
                  preferences every time you visit a site and some services and
                  functionalities may not work.
                </p>
                <h6> Changes to Our Cookies Policy </h6>
                <p>
                  We may update our Cookies Policy from time to time. Any
                  changes we may make to our Cookies Policy in the future will
                  be posted on this page.
                </p>
                <h6>Contact Us </h6>
                <p>
                  If you have any questions about our use of cookies, you can
                  contact us at [contact@email.com].
                </p>

                {/* <ul className="list-dot">
                  <li>
                    {" "}
                    You agree to use the services provided by [Hospital Name]
                    solely for lawful and legitimate purposes.
                  </li>
                  <li>
                    {" "}
                    You may not use our services in any manner that could
                    damage, disable, overburden, or impair the functionality of
                    our website.
                  </li>
                  <li>
                    {" "}
                    Unauthorized attempts to access or modify any information on
                    our website are strictly prohibited.
                  </li>
                </ul> */}
                {/*  <p className="mgb-32">
                  Etiam eleifend metus at nunc ultricies facilisis. Morbi
                  finibus tristique interdum. Nullam vel eleifend est, eu
                  posuere risus. Vestibulum ligula ex, ullamcorper sit amet
                  molestie
                </p> */}
                {/* <h6>3. Privacy Policy</h6> */}
                {/*  <p>
                  Your use of our services is also governed by our Privacy
                  Policy, which outlines how we collect, use, and protect your
                  personal information. By using our services, you consent to
                  the terms of our Privacy Policy.
                </p> */}
                {/* <p>
                  In malesuada neque quis libero laoreet posuere. In consequat
                  vitae ligula quis rutrum. Morbi dolor orci, maximus a pulvinar
                  sed, bibendum ac lacus. Suspendisse in consectetur lorem.
                  Pellentesque habitant morbi tristique senectus et netus et
                  malesuada fames ac turpis egestas. Aliquam elementum, est sed
                  interdum cursus, felis ex pharetra nisi, ut elementum tortor
                  urna eu nulla. Donec rhoncus in purus quis
                </p>
                <p className="mgb-32">
                  Etiam eleifend metus at nunc ultricies facilisis. Morbi
                  finibus tristique interdum. Nullam vel eleifend est, eu
                  posuere risus. Vestibulum ligula ex, ullamcorper sit amet
                  molestie a, finibus nec ex.
                </p> */}
                {/*  <h6>4. Medical Information Disclaimer</h6> */}
                {/* <p>
                  Etiam eleifend metus at nunc ultricies facilisis. Morbi
                  finibus tristique interdum. Nullam vel eleifend est, eu
                  posuere risus. Vestibulum ligula ex, ullamcorper sit amet
                  molestie a, finibus nec ex.
                </p> */}
                {/* <ul className="list-dot">
                  <li>
                    {" "}
                    The content provided on our website is for informational
                    purposes only and does not constitute medical advice.
                    Consult with a qualified healthcare professional for medical
                    concerns.
                  </li>
                  <li>
                    {" "}
                    Hospital is not responsible for any decisions or actions
                    taken based on information obtained from our website.
                  </li> */}
                {/* <li>
                    {" "}
                    Nullam vel eleifend est, eu posuere risus. Vestibulum ligula
                    ex, ullamcorper sit amet molestie a, finibus nec ex.
                  </li> */}
                {/*  </ul> */}
                {/*  <p className="mgb-32">
                  Etiam eleifend metus at nunc ultricies facilisis. Morbi
                  finibus tristique interdum. Nullam vel eleifend est, eu
                  posuere risus. Vestibulum ligula ex, ullamcorper sit amet
                  molestie
                </p> */}
                {/* <h6>5. Intellectual Property</h6> */}
                {/*  <p>
                  All content, trademarks, logos, and intellectual property on
                  the [Hospital Name] website are the property of [Hospital
                  Name] and its licensors.
                </p> */}
                {/*  <p>
                  In malesuada neque quis libero laoreet posuere. In consequat
                  vitae ligula quis rutrum. Morbi dolor orci, maximus a pulvinar
                  sed, bibendum ac lacus. Suspendisse in consectetur lorem.
                  Pellentesque habitant morbi tristique senectus et netus et
                  malesuada fames ac turpis egestas. Aliquam elementum, est sed
                  interdum cursus, felis ex pharetra nisi, ut elementum tortor
                  urna eu nulla. Donec rhoncus in purus quis blandit.
                </p> */}
                <p>
                  You may not reproduce, distribute, display, or create
                  derivative works of any part of our website without express
                  written consent from .
                </p>
              </TabPanel>
              <TabPanel className="term-content animation-tab">
                <h6>2. Limitations</h6>
                <p>
                  Etiam eleifend metus at nunc ultricies facilisis. Morbi
                  finibus tristique interdum. Nullam vel eleifend est, eu
                  posuere risus. Vestibulum ligula ex, ullamcorper sit amet
                  molestie a, finibus nec ex.
                </p>
                <ul className="list-dot">
                  <li>
                    {" "}
                    Aliquam elementum, est sed interdum cursus, felis ex
                    pharetra nisi, ut elementum tortor urna eu nulla. Donec
                    rhoncus in purus quis blandit.
                  </li>
                  <li> Etiam eleifend metus at nunc ultricies facilisis.</li>
                  <li>
                    {" "}
                    Nullam vel eleifend est, eu posuere risus. Vestibulum ligula
                    ex, ullamcorper sit amet molestie a, finibus nec ex.
                  </li>
                </ul>
                <p>
                  Etiam eleifend metus at nunc ultricies facilisis. Morbi
                  finibus tristique interdum. Nullam vel eleifend est, eu
                  posuere risus. Vestibulum ligula ex, ullamcorper sit amet
                  molestie
                </p>
              </TabPanel>
              <TabPanel className="term-content animation-tab">
                <h6>3. Revisions and errata</h6>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Integer sed euismod justo, sit amet efficitur dui. Aliquam
                  sodales vestibulum velit, eget sollicitudin quam. Donec non
                  aliquam eros. Etiam sit amet lectus vel justo dignissim
                  condimentum.
                </p>
                <p>
                  In malesuada neque quis libero laoreet posuere. In consequat
                  vitae ligula quis rutrum. Morbi dolor orci, maximus a pulvinar
                  sed, bibendum ac lacus. Suspendisse in consectetur lorem.
                  Pellentesque habitant morbi tristique senectus et netus et
                  malesuada fames ac turpis egestas. Aliquam elementum, est sed
                  interdum cursus, felis ex pharetra nisi, ut elementum tortor
                  urna eu nulla. Donec rhoncus in purus quis
                </p>
                <p>
                  Etiam eleifend metus at nunc ultricies facilisis. Morbi
                  finibus tristique interdum. Nullam vel eleifend est, eu
                  posuere risus. Vestibulum ligula ex, ullamcorper sit amet
                  molestie a, finibus nec ex.
                </p>
              </TabPanel>
              <TabPanel className="term-content animation-tab">
                <h6>4. Site terms of use modifications</h6>
                <p>
                  Etiam eleifend metus at nunc ultricies facilisis. Morbi
                  finibus tristique interdum. Nullam vel eleifend est, eu
                  posuere risus. Vestibulum ligula ex, ullamcorper sit amet
                  molestie a, finibus nec ex.
                </p>
                <ul className="list-dot">
                  <li>
                    {" "}
                    Aliquam elementum, est sed interdum cursus, felis ex
                    pharetra nisi, ut elementum tortor urna eu nulla. Donec
                    rhoncus in purus quis blandit.
                  </li>
                  <li> Etiam eleifend metus at nunc ultricies facilisis.</li>
                  <li>
                    {" "}
                    Nullam vel eleifend est, eu posuere risus. Vestibulum ligula
                    ex, ullamcorper sit amet molestie a, finibus nec ex.
                  </li>
                </ul>
                <p>
                  Etiam eleifend metus at nunc ultricies facilisis. Morbi
                  finibus tristique interdum. Nullam vel eleifend est, eu
                  posuere risus. Vestibulum ligula ex, ullamcorper sit amet
                  molestie
                </p>
              </TabPanel>
              <TabPanel className="term-content animation-tab">
                <h6>5. Risks</h6>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Integer sed euismod justo, sit amet efficitur dui. Aliquam
                  sodales vestibulum velit, eget sollicitudin quam. Donec non
                  aliquam eros. Etiam sit amet lectus vel justo dignissim
                  condimentum.
                </p>
                <p>
                  In malesuada neque quis libero laoreet posuere. In consequat
                  vitae ligula quis rutrum. Morbi dolor orci, maximus a pulvinar
                  sed, bibendum ac lacus. Suspendisse in consectetur lorem.
                  Pellentesque habitant morbi tristique senectus et netus et
                  malesuada fames ac turpis egestas. Aliquam elementum, est sed
                  interdum cursus, felis ex pharetra nisi, ut elementum tortor
                  urna eu nulla. Donec rhoncus in purus quis blandit.
                </p>
                <p>
                  Etiam eleifend metus at nunc ultricies facilisis. Morbi
                  finibus tristique interdum. Nullam vel eleifend est, eu
                  posuere risus. Vestibulum ligula ex, ullamcorper sit amet
                  molestie{" "}
                </p>
              </TabPanel>
            </div>
          </div>
        </Tabs>
      </div>
    </section>
  );
}

export default CookieTerm;
