import React from "react";

const GroupAvatar = ({ data }) => {
  const len = data.hospital_certificate.length;
  const remaining = len > 4 ? len - 4 : 0;
  console.log("🚀 + GroupAvatar + len:", len);
  return (
    <div>
      <style>
        {`
          .avatar-group {
            display: flex;
            align-items: center;
            justify-content: end;
          }
          .avatar {
            position: relative;
            transition: 0.2s;
          }
          .avatar:not(:first-child) {
            margin-left: -0.8rem;
          }
          .avatar:hover {
            z-index: 1;
            transform: translateY(-0.5rem);
          }
          .avatar-name {
            position: absolute;
            bottom: calc(100% + 0.5rem);
            left: 50%;
            transform: translateX(-50%);
            padding: 0.5rem 0.75rem;
            border-radius: 0.25rem;
            background-color: rgba(0, 0, 0, 0.7);
            color: #fff;
            font-size: 0.875rem;
            white-space: nowrap;
            opacity: 0;
            visibility: hidden;
            transition: 0.2s;
          }
          .avatar-name::before {
            content: "";
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
            border: 0.5rem solid;
            border-color: rgba(0, 0, 0, 0.7) transparent transparent transparent;
          }
          .avatar:hover .avatar-name {
            opacity: 1;
            visibility: visible;
          }
          .avatar img {
            width: 2.5rem;
            height: 2.5rem;
            display: block;
            object-fit: cover;
            border-radius: 50%;
            border: 3px solid #e7e7e7;
            box-shadow: 0 0.25rem 1rem rgba(119,119,119, 0.1);
          }
        `}
      </style>
      <div className="avatar-group">
        {data?.hospital_certificate?.slice(0, 3).map((data, index) => (
          <>
            <div className="avatar">
              <span className="avatar-name">{data.title}</span>
              <img
                src={`${process.env.REACT_APP_API_URL}${data.logo}`}
                alt={`Hospital Certificate ${index + 1}`}
              />
            </div>
          </>
        ))}
        {data?.hospital_certificate?.length > 4 && (
          <div className="avatar">
            <span className="avatar-name">More Certificates</span>
            <span className="avatar-additional">{remaining}+</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default GroupAvatar;
