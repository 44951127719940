import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

function PrivacyTerm() {
  return (
    <section className="term-section">
      <div className="tf-container">
        <Tabs className="row tf-tab">
          <div className="col-lg-4">
            <TabList className="menu-tab tab-term po-sticky">
              <Tab className="ct-tab">1. Acceptance of Terms </Tab>
              <Tab className="ct-tab">2. Use of Services </Tab>
              <Tab className="ct-tab">3. Privacy Policy</Tab>
              <Tab className="ct-tab">4. Medical Information Disclaimer</Tab>
              <Tab className="ct-tab">5. Intellectual Property</Tab>
            </TabList>
          </div>
          <div className="col-lg-8">
            <div className="content-tab">
              <TabPanel className="term-content animation-tab">
                <h4>Privacy Policy</h4>
                <h6>Last updated: [Date]</h6>

                <p>
                  Welcome to website. This Privacy Policy outlines how we
                  collect, use, disclose, and protect your information when you
                  visit our website or use our services.
                </p>

                <h6>Information We Collect</h6>
                {/* <p>
                  Etiam eleifend metus at nunc ultricies facilisis. Morbi
                  finibus tristique interdum. Nullam vel eleifend est, eu
                  posuere risus. Vestibulum ligula ex, ullamcorper sit amet
                  molestie a, finibus nec ex.
                </p> */}
                <h6> Personal Information</h6>

                <p>
                  We may collect personal information such as names, contact
                  information, and demographic details when you voluntarily
                  submit this information through forms on our website.
                </p>
                <h6> Health Information</h6>

                <p>
                  In the course of providing medical services, we may collect
                  health-related information necessary for patient care and
                  treatment.
                </p>
                <h6> Non-Personal Information</h6>

                <p>
                  We may also collect non-personal information, such as browser
                  type, device information, and website usage statistics, to
                  improve our website and services.
                </p>
                <h6>How We Use Your Information</h6>
                <h6> Providing Services</h6>

                <p>
                  We use the collected information to provide medical services,
                  respond to inquiries, and fulfill requests made through our
                  website.
                </p>
                <h6>Improving Services</h6>

                <p>
                  We may use non-personal information to analyze website usage
                  patterns and improve our website's functionality and user
                  experience.
                </p>
                <h6>Legal Compliance</h6>

                <p>
                  We may use and disclose information as required by law,
                  including compliance with court orders, legal processes, or
                  government requests.
                </p>
                <h6> Information Sharing</h6>

                <p>
                  We do not sell, trade, or otherwise transfer your personal
                  information to third parties without your consent, except as
                  detailed in this Privacy Policy.
                </p>
                <h6>Security</h6>

                <p>
                  We implement reasonable security measures to protect your
                  personal information from unauthorized access, disclosure,
                  alteration, and destruction.
                </p>
                <h6> Cookies</h6>

                <p>
                  Please refer to our Cookies Policy for information about the
                  use of cookies on our website.
                </p>
                <h6> Your Rights</h6>

                <p>
                  You have the right to access, correct, or delete your personal
                  information. If you have any concerns or requests, please
                  contact us at [contact@email.com].
                </p>
                <h6> Changes to Our Privacy Policy</h6>

                <p>
                  We may update our Privacy Policy periodically. Any changes
                  will be posted on this page, and the date of the latest
                  revision will be indicated.
                </p>
                <h6> Contact Us</h6>

                <p>
                  If you have any questions or concerns about our Privacy
                  Policy, you can contact us at [contact@email.com].
                </p>
                <ul className="list-dot">
                  {/*   <li>
                    {" "}
                    You agree to use the services provided by [Hospital Name]
                    solely for lawful and legitimate purposes.
                  </li> */}
                  {/*  <li>
                    {" "}
                    You may not use our services in any manner that could
                    damage, disable, overburden, or impair the functionality of
                    our website.
                  </li> */}
                  <li>
                    {" "}
                    Unauthorized attempts to access or modify any information on
                    our website are strictly prohibited.
                  </li>
                </ul>
                {/*  <p className="mgb-32">
                  Etiam eleifend metus at nunc ultricies facilisis. Morbi
                  finibus tristique interdum. Nullam vel eleifend est, eu
                  posuere risus. Vestibulum ligula ex, ullamcorper sit amet
                  molestie
                </p> */}
                {/*  <h6>3. Privacy Policy</h6> */}
                {/* <p>
                  Your use of our services is also governed by our Privacy
                  Policy, which outlines how we collect, use, and protect your
                  personal information. By using our services, you consent to
                  the terms of our Privacy Policy.
                </p> */}
                {/* <p>
                  In malesuada neque quis libero laoreet posuere. In consequat
                  vitae ligula quis rutrum. Morbi dolor orci, maximus a pulvinar
                  sed, bibendum ac lacus. Suspendisse in consectetur lorem.
                  Pellentesque habitant morbi tristique senectus et netus et
                  malesuada fames ac turpis egestas. Aliquam elementum, est sed
                  interdum cursus, felis ex pharetra nisi, ut elementum tortor
                  urna eu nulla. Donec rhoncus in purus quis
                </p>
                <p className="mgb-32">
                  Etiam eleifend metus at nunc ultricies facilisis. Morbi
                  finibus tristique interdum. Nullam vel eleifend est, eu
                  posuere risus. Vestibulum ligula ex, ullamcorper sit amet
                  molestie a, finibus nec ex.
                </p> */}
                {/*  <h6>4. Medical Information Disclaimer</h6> */}
                {/* <p>
                  Etiam eleifend metus at nunc ultricies facilisis. Morbi
                  finibus tristique interdum. Nullam vel eleifend est, eu
                  posuere risus. Vestibulum ligula ex, ullamcorper sit amet
                  molestie a, finibus nec ex.
                </p> */}

                {/*  <li>
                    {" "}
                    The content provided on our website is for informational
                    purposes only and does not constitute medical advice.
                    Consult with a qualified healthcare professional for medical
                    concerns.
                  </li> */}
                {/*   <li>
                    {" "}
                    Hospital is not responsible for any decisions or actions
                    taken based on information obtained from our website.
                  </li> */}
                {/* <li>
                    {" "}
                    Nullam vel eleifend est, eu posuere risus. Vestibulum ligula
                    ex, ullamcorper sit amet molestie a, finibus nec ex.
                  </li> */}

                {/*  <p className="mgb-32">
                  Etiam eleifend metus at nunc ultricies facilisis. Morbi
                  finibus tristique interdum. Nullam vel eleifend est, eu
                  posuere risus. Vestibulum ligula ex, ullamcorper sit amet
                  molestie
                </p> */}
                {/* <h6>5. Intellectual Property</h6>
                <p>
                  All content, trademarks, logos, and intellectual property on
                  the [Hospital Name] website are the property of [Hospital
                  Name] and its licensors.
                </p> */}
                {/*  <p>
                  In malesuada neque quis libero laoreet posuere. In consequat
                  vitae ligula quis rutrum. Morbi dolor orci, maximus a pulvinar
                  sed, bibendum ac lacus. Suspendisse in consectetur lorem.
                  Pellentesque habitant morbi tristique senectus et netus et
                  malesuada fames ac turpis egestas. Aliquam elementum, est sed
                  interdum cursus, felis ex pharetra nisi, ut elementum tortor
                  urna eu nulla. Donec rhoncus in purus quis blandit.
                </p> */}
                {/* <p>
                  You may not reproduce, distribute, display, or create
                  derivative works of any part of our website without express
                  written consent from .
                </p> */}
              </TabPanel>
              <TabPanel className="term-content animation-tab">
                <h6>2. Limitations</h6>
                <p>
                  Etiam eleifend metus at nunc ultricies facilisis. Morbi
                  finibus tristique interdum. Nullam vel eleifend est, eu
                  posuere risus. Vestibulum ligula ex, ullamcorper sit amet
                  molestie a, finibus nec ex.
                </p>
                <ul className="list-dot">
                  <li>
                    {" "}
                    Aliquam elementum, est sed interdum cursus, felis ex
                    pharetra nisi, ut elementum tortor urna eu nulla. Donec
                    rhoncus in purus quis blandit.
                  </li>
                  <li> Etiam eleifend metus at nunc ultricies facilisis.</li>
                  <li>
                    {" "}
                    Nullam vel eleifend est, eu posuere risus. Vestibulum ligula
                    ex, ullamcorper sit amet molestie a, finibus nec ex.
                  </li>
                </ul>
                <p>
                  Etiam eleifend metus at nunc ultricies facilisis. Morbi
                  finibus tristique interdum. Nullam vel eleifend est, eu
                  posuere risus. Vestibulum ligula ex, ullamcorper sit amet
                  molestie
                </p>
              </TabPanel>
              <TabPanel className="term-content animation-tab">
                <h6>3. Revisions and errata</h6>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Integer sed euismod justo, sit amet efficitur dui. Aliquam
                  sodales vestibulum velit, eget sollicitudin quam. Donec non
                  aliquam eros. Etiam sit amet lectus vel justo dignissim
                  condimentum.
                </p>
                <p>
                  In malesuada neque quis libero laoreet posuere. In consequat
                  vitae ligula quis rutrum. Morbi dolor orci, maximus a pulvinar
                  sed, bibendum ac lacus. Suspendisse in consectetur lorem.
                  Pellentesque habitant morbi tristique senectus et netus et
                  malesuada fames ac turpis egestas. Aliquam elementum, est sed
                  interdum cursus, felis ex pharetra nisi, ut elementum tortor
                  urna eu nulla. Donec rhoncus in purus quis
                </p>
                <p>
                  Etiam eleifend metus at nunc ultricies facilisis. Morbi
                  finibus tristique interdum. Nullam vel eleifend est, eu
                  posuere risus. Vestibulum ligula ex, ullamcorper sit amet
                  molestie a, finibus nec ex.
                </p>
              </TabPanel>
              <TabPanel className="term-content animation-tab">
                <h6>4. Site terms of use modifications</h6>
                <p>
                  Etiam eleifend metus at nunc ultricies facilisis. Morbi
                  finibus tristique interdum. Nullam vel eleifend est, eu
                  posuere risus. Vestibulum ligula ex, ullamcorper sit amet
                  molestie a, finibus nec ex.
                </p>
                <ul className="list-dot">
                  <li>
                    {" "}
                    Aliquam elementum, est sed interdum cursus, felis ex
                    pharetra nisi, ut elementum tortor urna eu nulla. Donec
                    rhoncus in purus quis blandit.
                  </li>
                  <li> Etiam eleifend metus at nunc ultricies facilisis.</li>
                  <li>
                    {" "}
                    Nullam vel eleifend est, eu posuere risus. Vestibulum ligula
                    ex, ullamcorper sit amet molestie a, finibus nec ex.
                  </li>
                </ul>
                <p>
                  Etiam eleifend metus at nunc ultricies facilisis. Morbi
                  finibus tristique interdum. Nullam vel eleifend est, eu
                  posuere risus. Vestibulum ligula ex, ullamcorper sit amet
                  molestie
                </p>
              </TabPanel>
              <TabPanel className="term-content animation-tab">
                <h6>5. Risks</h6>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Integer sed euismod justo, sit amet efficitur dui. Aliquam
                  sodales vestibulum velit, eget sollicitudin quam. Donec non
                  aliquam eros. Etiam sit amet lectus vel justo dignissim
                  condimentum.
                </p>
                <p>
                  In malesuada neque quis libero laoreet posuere. In consequat
                  vitae ligula quis rutrum. Morbi dolor orci, maximus a pulvinar
                  sed, bibendum ac lacus. Suspendisse in consectetur lorem.
                  Pellentesque habitant morbi tristique senectus et netus et
                  malesuada fames ac turpis egestas. Aliquam elementum, est sed
                  interdum cursus, felis ex pharetra nisi, ut elementum tortor
                  urna eu nulla. Donec rhoncus in purus quis blandit.
                </p>
                <p>
                  Etiam eleifend metus at nunc ultricies facilisis. Morbi
                  finibus tristique interdum. Nullam vel eleifend est, eu
                  posuere risus. Vestibulum ligula ex, ullamcorper sit amet
                  molestie{" "}
                </p>
              </TabPanel>
            </div>
          </div>
        </Tabs>
      </div>
    </section>
  );
}

export default PrivacyTerm;
