import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import OtpInput from "react-otp-input";
import { checkOTP, sendOTP } from "../../../Services/auth.services";
import { Link } from "react-router-dom";

function ModalOtp({ show, setShow }) {
  //const [show, setShow] = useState(false);
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [modalOtp, setOtpModal] = useState(false);
  const [response, setResponse] = useState({
    otp: "",
    login: "",
  });
  //console.log(modalOtp, "5656");

  const handleClose = () => {
    setShow(false);
    setOtp("");
    setPhone("");
    setOtpModal(false);
    setResponse({
      otp: "",
      login: "",
    });
  };

  const handleSubmit = async () => {
    if (phone === "") {
      setResponse((prev) => ({
        ...prev,
        otp: {
          msg: "please enter mobile number",
          class: "text-danger",
        },
      }));
    } else {
      const formData = {
        number: phone,
      };

      try {
        const response = await sendOTP(formData, setOtpModal);
        //console.log(response.otp, "message");
        setResponse((prev) => ({
          ...prev,
          otp: {
            msg: response,
            class: "text-sucess",
          },
        }));
      } catch (err) {
        console.log(err);
      }
    }
  };

  const otpSubmit = async () => {
    const details = {
      number: phone,
    };
    try {
      const response = await checkOTP(details, otp);
      console.log(response, "response");
      //setResponse((prev) => ({ ...prev, login: response }));
      if (response === "OTP VERIFIED & LOGGING IN") {
        setResponse((prev) => ({
          ...prev,
          login: {
            msg: response,
            class: "text-sucess",
          },
        }));
        setTimeout(() => {
          window.open("https://hadmin.prismaticsoft.com", "_blank");

          handleClose();
        }, 3000);
      } else if (response === "Invalid OTP") {
        setResponse((prev) => ({
          ...prev,
          login: {
            msg: response,
            class: "text-danger",
          },
        }));
      } else if (response === "verified") {
        setResponse((prev) => ({
          ...prev,
          login: {
            msg: response,
            class: "text-danger",
          },
        }));
      } else {
        setResponse((prev) => ({
          ...prev,
          login: {
            msg: response,
            class: "text-danger",
          },
        }));
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        centered="true"
        size="sm"
        className="login-modal-container"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {modalOtp ? (
              <div className="login-modal-title">
                <p>Enter OTP</p>
              </div>
            ) : (
              <>
                <p
                  style={{
                    fontSize: "18px",
                    paddingLeft: "39px",
                    paddingTop: "7px",
                  }}
                >
                  Enter Mobile number
                </p>
                <p className={`${response.otp.class} px-4 login-modal-error `}>
                  {response.otp.msg}
                </p>
              </>
            )}
          </Modal.Title>
        </Modal.Header>
        {modalOtp ? (
          <>
            <Modal.Body>
              <p className={`${response.login.class} login-modal-error `}>
                {response.login.msg}
              </p>
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={4}
                renderSeparator={<span>-</span>}
                renderInput={(props) => (
                  <input
                    {...props}
                    style={{
                      width: "50px",
                      margin: "0 5px",
                      padding: "0 0 0 20px",
                    }}
                  />
                )}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={otpSubmit}>
                Submit OTP
              </Button>
            </Modal.Footer>
          </>
        ) : (
          <>
            <Modal.Body>
              <input
                type="number"
                id="name"
                placeholder="Enter your mobile number"
                onChange={(e) => setPhone(e.target.value)}
                style={{ "-moz-appearance": "textfield" }}
                pattern="\d*"
                value={phone}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleSubmit}>
                Send OTP
              </Button>
            </Modal.Footer>
          </>
        )}
      </Modal>
    </>
  );
}

export default ModalOtp;
