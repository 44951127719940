import React from "react";
import Dropdown from "react-dropdown";
import { GlobalData } from "../../UserContext/userContext";
const options = [
  { value: "All Location", label: "All Location" },
  { value: "Dubai", label: "Dubai" },
  { value: "Ajman", label: "Ajman" },
  { value: "Abu Dhabi", label: "Abu Dhabi" },
  { value: "Sharjah", label: "Sharjah" },
  { value: "Umm Al Quwain", label: "Umm Al Quwain" },
  { value: "Ras Al Khaimah", label: "Ras Al Khaimah" },
  { value: "Fujairah", label: "Fujairah" },
];

// function SelectLocation() {
//   const { dropDownLocation, setDropDownLocation } = GlobalData();

//   return (
//     <Dropdown
//       options={options}
//       className="react-dropdown select-location"
//       value={dropDownLocation}
//       onChange={(e) => setDropDownLocation(e.value)}
//     />
//   );
// }

// export default SelectLocation;
function SelectLocation({ options, value, onChange }) {
  // const { dropDownLocation, setDropDownLocation } = GlobalData();

  return (
    <Dropdown
      options={options}
      className="react-dropdown select-location"
      value={value}
      onChange={onChange}
    />
  );
}

export default SelectLocation;
