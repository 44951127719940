import img1 from "../images/logo-company/cty8.png";
import img2 from "../images/logo-company/cty11.png";
import img3 from "../images/logo-company/cty4.png";
import img4 from "../images/logo-company/cty7.png";
import img5 from "../images/logo-company/cty2.png";
import img6 from "../images/logo-company/cty9.png";
import img7 from "../images/logo-company/cty1.png";
import img8 from "../images/logo-company/cty10.png";

const dataJobs = [
  {
    id: 1,
    img: img1,
    stt: "active",
    cate: "Rockstar Games New York",
    title: "Project manager",
    map: "Las Vegas, NV 89107, USA",
    time: "2 days ago",
    price: "$83,000 - $110,000 ",
    apply: "22 days left to apply",
    jobs1: "Full-time",
    jobs2: "Remote",
  },
  {
    id: 2,
    img: img2,
    cate: "Rockstar Games New York",
    title: "Senior UI/UX Designer",
    map: "Las Vegas, NV 89107, USA",
    time: "2 days ago",
    price: "$83,000 - $110,000 ",
    apply: "22 days left to apply",
    jobs1: "Freelancer",
    jobs2: "Remote",
  },
  {
    id: 3,
    img: img3,
    cate: "Rockstar Games New York",
    title: "Full Stack Development",
    map: "Las Vegas, NV 89107, USA",
    time: "2 days ago",
    price: "$83,000 - $110,000 ",
    apply: "22 days left to apply",
    jobs1: "Temporary",
    jobs2: "On-site",
  },
  {
    id: 4,
    img: img4,
    cate: "Rockstar Games New York",
    title: "Senior DevOps Engineer",
    map: "Las Vegas, NV 89107, USA",
    time: "2 days ago",
    price: "$83,000 - $110,000 ",
    apply: "22 days left to apply",
    jobs1: "Part-time",
    jobs2: "Remote",
  },
  {
    id: 5,
    img: img5,
    cate: "Rockstar Games New York",
    title: "Project manager",
    map: "Las Vegas, NV 89107, USA",
    time: "2 days ago",
    price: "$83,000 - $110,000 ",
    apply: "22 days left to apply",
    jobs1: "Freelancer",
    jobs2: "Remote",
  },
  {
    id: 6,
    img: img6,
    cate: "Rockstar Games New York",
    title: "Social Media Marketing ",
    map: "Las Vegas, NV 89107, USA",
    time: "2 days ago",
    price: "$83,000 - $110,000 ",
    apply: "22 days left to apply",
    jobs1: "Contract",
    jobs2: "On-site",
  },
  {
    id: 7,
    img: img7,
    cate: "Rockstar Games New York",
    title: "Senior UI/UX Designer",
    map: "Las Vegas, NV 89107, USA",
    time: "2 days ago",
    price: "$83,000 - $110,000 ",
    apply: "22 days left to apply",
    jobs1: "Full-time",
    jobs2: "Remote",
  },
  {
    id: 8,
    img: img8,
    cate: "Rockstar Games New York",
    title: "Social Media Marketing ",
    map: "Las Vegas, NV 89107, USA",
    time: "2 days ago",
    price: "$83,000 - $110,000 ",
    apply: "22 days left to apply",
    jobs1: "Freelancer",
    jobs2: "Remote",
  },
  {
    id: 9,
    img: img5,
    cate: "Rockstar Games New York",
    title: "Senior UI/UX Designer",
    map: "Las Vegas, NV 89107, USA",
    time: "2 days ago",
    price: "$83,000 - $110,000 ",
    apply: "22 days left to apply",
    jobs1: "Temporary",
    jobs2: "On-site",
  },
  {
    id: 10,
    img: img8,
    cate: "Rockstar Games New York",
    title: "Full Stack Development",
    map: "Las Vegas, NV 89107, USA",
    time: "2 days ago",
    price: "$83,000 - $110,000 ",
    apply: "22 days left to apply",
    jobs1: "Full-time",
    jobs2: "Remote",
  },

  {
    id: 11,
    img: img3,
    cate: "Rockstar Games New York",
    title: "Project manager",
    map: "Las Vegas, NV 89107, USA",
    time: "2 days ago",
    price: "$83,000 - $110,000 ",
    apply: "22 days left to apply",
    jobs1: "Freelancer",
    jobs2: "Remote",
  },
  {
    id: 12,
    img: img4,
    cate: "Rockstar Games New York",
    title: "Senior UI/UX Designer",
    map: "Las Vegas, NV 89107, USA",
    time: "2 days ago",
    price: "$83,000 - $110,000 ",
    apply: "22 days left to apply",
    jobs1: "Temporary",
    jobs2: "On-site",
  },
];

export default dataJobs;
