import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { createContext } from "react";

const FacilityGlobal = createContext();

const FacilityContext = ({ children }) => {
  const [facility, setFacility] = useState("All Facilities");

  // useEffect(() => {
  //   const facility = localStorage.getItem("facilityType");

  //   if (facility) {
  //     setFacility(facility);
  //   } else {
  //     setFacility("Hospital");
  //   }
  // }, []);
  return (
    <FacilityGlobal.Provider value={{ facility, setFacility }}>
      {children}
    </FacilityGlobal.Provider>
  );
};

export default FacilityContext;

export const FacilityGlobalData = () => {
  return useContext(FacilityGlobal);
};
